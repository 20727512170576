import React, { useEffect, useState } from 'react'
import Task from './Task';
import { Modal } from 'react-bootstrap';

export default function WorkLaw({ data, assignee_list, facilitiesList, columns, sections, handelSectionChange }) {

    const [showTask, setShowTask] = useState(false)
    const [selectedTask, setSelectedTask] = useState({})
    const [editAssignee, setEditAssignee] = useState(false)
    const [statuses, setStatuses] = useState([]);

    // useEffect(() => {
    //     setSections(Object.keys(data)?.map((each) => {
    //         return {
    //             tab: each,
    //             isOpen: false
    //         }
    //     }))
    // }, [data])


    useEffect(() => {
        setStatuses(columns);
    }, [data]);

    const handleManageSection = (tab) => {
        handelSectionChange(tab);
    }

    const handleOpenTask = (task) => {
        setShowTask(true);
        setSelectedTask(task);
    };

    const formatDate = (dateString) => {
        // Parse the date string
        const [day, month, year] = dateString.split('-');
        const date = new Date(`${year}-${month}-${day}`);

        // Format the date
        const options = { weekday: 'long', month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    return (
        <>
            <div style={{ overflow: "auto", height: "380px" }}>
                <table style={{ width: "100%" }}>
                    <thead style={{ position: "sticky", top: 0 }}>
                        <tr>
                            {/* <th className='tasks-header-items-container'>
                                <div className='tasks-header-items'>To-Do {calculateTotalTodoTasks()}</div>
                            </th>
                            <th className='tasks-header-items-container'>
                                <div className='tasks-header-items'>In Progress {calculateTotalInprogressTasks()}</div>
                            </th>
                            <th className='tasks-header-items-container'>
                                <div className='tasks-header-items'>Approvals {calculateTotalApprovalTasks()}</div>
                            </th>
                            <th className='tasks-header-items-container'>
                                <div className='tasks-header-items'>Completed {calculateTotalCompletedTasks()}</div>
                            </th> */}
                            {columns?.map((column, index) => {
                                return (
                                    <th key={index} className='tasks-header-items-container'>
                                        <div className='tasks-header-items'>{column.charAt(0).toUpperCase() + column.slice(1)}</div>
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {/* {data?.map((task, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <tr role='button' onClick={() => handleManageSection(Object.keys(task)[0])}>
                                        <td colSpan={4} style={{ backgroundColor: "#FFFFF", minHeight: "40px", display: "flex", alignItems: "center", gap: "20px", padding: "0px 10px" }}>
                                            {sections[index]?.isOpen ? <img src='./images/icons/DownArrow.svg' alt="Down arrow" /> : <img src='./images/icons/RightArrow.svg' alt="Right Arrow" />}
                                            <div className='d-flex gap-2'>
                                                <span>{Object.keys(task)[0]}</span>
                                                <span>({task[Object.keys(task)[0]]?.todo?.length + task[Object.keys(task)[0]]?.inprogress?.length + task[Object.keys(task)[0]]?.approval?.length + task[Object.keys(task)[0]]?.completed?.length} tasks)</span>
                                            </div>
                                        </td>
                                    </tr>
                                    {sections[index]?.isOpen && <tr>
                                        <td className='tasks-body-items'>
                                            {task[Object.keys(task)[0]]?.todo?.map((todo, i) => {
                                                const facilityColor = facilitiesList.find(facility => facility.name === todo?.facility)?.backgroundColor;
                                                return <Task key={i} task={{ ...todo, facilityColor }} handleOpenTask={handleOpenTask} />
                                            })}
                                        </td>
                                        <td className='tasks-body-items'>
                                            {task[Object.keys(task)[0]]?.inprogress?.map((inprogress, i) => {
                                                const facilityColor = facilitiesList.find(facility => facility.name === inprogress.facility)?.backgroundColor;
                                                return <Task key={i} task={{ ...inprogress, facilityColor }} handleOpenTask={handleOpenTask} />
                                            })}
                                        </td>
                                        <td className='tasks-body-items'>
                                            {task[Object.keys(task)[0]]?.approval?.map((approval, i) => {
                                                const facilityColor = facilitiesList.find(facility => facility.name === approval.facility)?.backgroundColor;
                                                return <Task key={i} task={{ ...approval, facilityColor }} handleOpenTask={handleOpenTask} />
                                            })}
                                        </td>
                                        <td className='tasks-body-items'>
                                            {task[Object.keys(task)[0]]?.completed?.map((completed, i) => {
                                                const facilityColor = facilitiesList.find(facility => facility.name === completed.facility)?.backgroundColor;
                                                return <Task key={i} task={{ ...completed, facilityColor }} handleOpenTask={handleOpenTask} />
                                            })}
                                        </td>
                                    </tr>}
                                </React.Fragment>
                            )
                        })} */}
                        {/* {console.log(Object.entries(data))} */}
                        {Object.entries(data).map(([category, tasks], index) => (
                            <React.Fragment key={index}>
                                <tr role='button' onClick={() => tasks.length > 0 && handleManageSection(category)}>
                                    <td colSpan={statuses.length} style={{ backgroundColor: "#FFFFF", minHeight: "40px", display: "flex", alignItems: "center", gap: "20px", padding: "0px 10px" }}>
                                        {sections[index]?.isOpen ? <img src='./images/icons/DownArrow.svg' alt="Down arrow" /> : <img src='./images/icons/RightArrow.svg' alt="Right Arrow" />}
                                        <div className='d-flex gap-2'>
                                            <span>{category}</span>
                                            <span>({tasks.length} tasks)</span>
                                        </div>
                                    </td>
                                </tr>
                                {tasks.length > 0 && sections[index]?.isOpen && (
                                    <tr>
                                        {statuses.map(status => (
                                            <td key={status} className='tasks-body-items'>
                                                {tasks.filter(task => task.status === status).map((task, i) => {
                                                    const facilityColor = facilitiesList.find(facility => facility.name === task.facility)?.backgroundColor;
                                                    return <Task key={i} task={{ ...task, facilityColor }} handleOpenTask={handleOpenTask} />
                                                })}
                                            </td>
                                        ))}
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
            {showTask && <Modal show={showTask} onHide={() => setShowTask(false)} centered>
                <Modal.Body>
                    <div className='d-flex flex-column gap-2'>
                        <div className='d-flex justify-content-end gap-2'>
                            <img src='./images/icons/DeleteIcon.svg' alt='delete' />
                            <img src='./images/icons/WrongIcon.svg' alt='close' role='button' onClick={() => {
                                setSelectedTask({})
                                setShowTask(false)
                            }} />
                        </div>
                        <div>
                            <h4>{selectedTask?.eventName}</h4>
                        </div>
                        <div className='d-flex align-items-center gap-3'>
                            <img src='./images/icons/Person.svg' alt='person' />
                            {editAssignee ?
                                <div>
                                    <select className='form-control'>
                                        {assignee_list.map((assignee, index) => {
                                            return <option key={index} selected={selectedTask?.assignedTo === assignee.name} value={assignee.name}>{assignee.name}</option>
                                        })}
                                    </select>
                                </div>
                                : <span>{selectedTask?.assignedTo}</span>}
                            {editAssignee ? <div className='d-flex gap-2'><span style={{ color: "red" }}>✗</span></div> : <img src='./images/icons/EditIcon.svg' alt='edit' onClick={() => setEditAssignee(!editAssignee)} />}
                        </div>
                        <div className='d-flex align-items-start gap-3'>
                            <img src='./images/icons/schedule.svg' alt='schedule' className='mt-1' />
                            <div>
                                <div>
                                    {formatDate(selectedTask?.scheduledDate)} , {selectedTask?.scheduledTime}
                                </div>
                                <div>
                                    {selectedTask?.scheduleTimeDesc}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center gap-3'>
                            <img src='./images/icons/alarm.svg' alt='remainder' />
                            <span>{selectedTask?.remainder}</span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span style={{ width: "30%" }}>Supervisor</span>
                            <span>{selectedTask?.supervisor}</span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span style={{ width: "30%" }}>SOP</span>
                            <span style={{ color: "blue", textDecoration: "underline" }}>{selectedTask?.sop}</span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span style={{ width: "30%" }}>FORM</span>
                            <span style={{ color: "blue", textDecoration: "underline" }}>{selectedTask?.form}</span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>}
        </>
    )
}
