import React, { useEffect } from 'react'

export default function Textarea({ object, handleFormData, formUse, rowIndex, colIndex, data }) {

    useEffect(() => {
        handleFormData && handleFormData(object.content + "-" + rowIndex + colIndex, "")
    }, [])

    return (
        <div>
            <label>{object.content}</label>
            <textarea className='form-control'
                onChange={(e) => formUse && handleFormData && handleFormData(object.content + "-" + rowIndex + colIndex, e.target.value)}
                value={data && data[object.content + "-" + rowIndex + colIndex]} 
                disabled={data && data}/>
        </div>
    )
}
