import React, { useState } from 'react'
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import FormLoader from '../Reusable_Components/FromLoader'
import { FaSearch } from 'react-icons/fa'
import axios from "../../axios-interceptor"
import { useDispatch, useSelector } from 'react-redux'
import { CREATE_PARTNER_COMPANY_FAILURE, CREATE_PARTNER_COMPANY_SUCCESS, createPartnerCompany, UPLOAD_DSC_SUCCESS, UPLOAD_LOGO_FAILURE, UPLOAD_LOGO_SUCCESS, uploadDscFile, uploadLogo } from '../../Redux/Actions/Actions'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { MdErrorOutline } from 'react-icons/md'
import AlertPopUp from "../Reusable_Components/Alert";
import { base_url } from '../../utilities'
import UploadComponent from '../Reusable_Components/UploadComponent'
import {
    handleCinInputChange, processAndValidateEmail, eleminateSpacesAtStartOfInputField, eleminateSpecialCharactersAtStartOfInputField,
    validateGstNumber, handleKeyPressForGst
} from "../Reusable_Components/ReusableFunctions";
import { set } from 'date-fns'


export default function AddPartnerCompany(props) {

    const dispatch = useDispatch()

    const loading = useSelector(state => state.loading)
    const createPartnerCompanySuccess = useSelector(state => state.createPartnerCompanySuccess)
    const createPartnerCompanyError = useSelector(state => state.createPartnerCompanyError)
    const uploadLogoSuccess = useSelector(state => state.uploadLogoSuccess)
    const uploadLogoError = useSelector(state => state.uploadLogoError)
    const uploadDscSuccess = useSelector(state => state.uploadDscSuccess)
    const uploadDscError = useSelector(state => state.uploadDscError)

    const [gstNumber, setGstNumber] = useState('')
    const [gstLoader, setGstLoader] = useState(false)
    const [companyDetails, setCompanyDetails] = useState({})
    const [slf, setSlf] = useState(false)
    const [water, setWater] = useState(false)
    const [electricity, setElectricity] = useState(false)
    const [fms, setFms] = useState(false)
    const [miscellaneous, setMiscellaneous] = useState(false)
    const [getNumberError, setGetNumberError] = useState(null)
    const [isValidGstNumber, setIsValidGstNumber] = useState(true)
    const [isCinValid, setIsCinValid] = useState(true)
    const [filesData, setFilesData] = useState([])
    const [dscFilesData, setDscFilesData] = useState([])
    const [email, setEmail] = useState('')
    const [shortName, setShortName] = useState('')
    const [emailError, setEmailError] = useState('')
    const [alertShow, setAlertShow] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertValue, setAlertValue] = useState('')
    const [alertButtons, setAlertButtons] = useState([])
    const [errorValue, setErrorValue] = useState(null)

    const [showBackConfirmation, setShowBackConfirmation] = useState(false)
    const handleBackConfirmation = () => setShowBackConfirmation(false);
    const handleProceed = () => {
        setShowBackConfirmation(false);
        // props?.setPage(1)
    }

    const handleGstNumberChange = (e) => {
        const input = e.target.value;
        const { isValid, uppercasedInput } = validateGstNumber(input);
        setGstNumber(uppercasedInput);
        setIsValidGstNumber(isValid);
    };

    const handleCinNumberChange = (e) => {
        handleCinInputChange(e,
            (truncatedInput) => setCompanyDetails({ ...companyDetails, cin: truncatedInput }),
            (isValid) => setIsCinValid(isValid)
        );
    };

    const handleEmail = (e) => {
        const input = e.target.value;
        const { processedInput, isValid } = processAndValidateEmail(input, email);
        setEmail(processedInput);
        if (processedInput === '') {
            setEmailError('Email is required');
        } else if (!isValid) {
            setEmailError('Enter a valid email address');
        } else {
            setEmailError('');
        }
    };

    const getGstDetails = () => {
        setGstLoader(true)
        axios.get(`${base_url}gstPortal/verifyGstin?gst_number=${gstNumber}`)
            .then(res => {
                setCompanyDetails(res.data.data)
                setGstLoader(false)
            })
            .catch((error) => {
                const errorDetails = JSON.parse(error.response.data.error_logs.status_desc);
                const errorMessage = errorDetails[0].ErrorMessage
                setErrorValue(errorMessage);
                setAlertShow(true);
                setAlertType('error');
                setAlertMessage('Error');
                setAlertValue(errorMessage);
                setAlertButtons([
                    {
                        name: 'Close',
                        action: () => {
                            setAlertShow(false);
                        }
                    }
                ]);
                setGetNumberError(error.response.data.message)
                setGstLoader(false);
            });
    }

    const addPartnerCompany = () => {
        let { registered_address, ...obj_without_address } = companyDetails;
        let short_name = shortName.trim();
        let email_id = email.trim();
        dispatch(createPartnerCompany({ ...registered_address, ...obj_without_address, slf, water, electricity, fms, misc: miscellaneous, logo: uploadLogoSuccess, dsc: uploadDscSuccess, short_name: short_name, email: email_id }))
    }

    const selectFile = (files) => {
        const processedFiles1 = [];
        files.forEach((file) => {
            const reader1 = new FileReader();
            reader1.onloadend = function () {
                const base64FileContent = reader1.result.split(",")[1];
                const processedFile1 = {
                    file_obj: base64FileContent,
                    file_name: file.file.name,
                    file_type: file.file.type,
                };
                processedFiles1.push(processedFile1);
                if (processedFiles1.length === files.length) {
                    setFilesData(processedFiles1);
                }
            };
            reader1.readAsDataURL(file.file);
        });
    }

    const selectDscFile = (files) => {
        const processedFiles1 = [];
        files.forEach((file) => {
            const reader1 = new FileReader();
            reader1.onloadend = function () {
                const base64FileContent = reader1.result.split(",")[1];
                const processedFile1 = {
                    file_obj: base64FileContent,
                    file_name: file.file.name,
                    file_type: file.file.type,
                };
                processedFiles1.push(processedFile1);
                if (processedFiles1.length === files.length) {
                    setDscFilesData(processedFiles1);
                }
            };
            reader1.readAsDataURL(file.file);
        });
    }


    const uploadLogoData = (index) => {
        if (gstNumber === '' || !companyDetails?.company_name) {
            dispatch({
                type: UPLOAD_LOGO_FAILURE,
                payload: "Please fill above fields before uploading logo"
            })
        } else {
            dispatch(uploadLogo(
                {
                    "files": [
                        {
                            "file_content": filesData[index]["file_obj"],
                            "object_type": "our_company_logo",
                            "file_type": filesData[index]["file_type"].split("/")[1],
                            "file_name": filesData[index]["file_name"],
                            "flag": null,
                            "file_description": "",
                            "company_name": companyDetails?.company_name,
                            "user_id": localStorage.getItem("username"),
                        }
                    ]
                }
            ))
        }
    }

    const uploadDsc = (index) => {
        if (gstNumber === '' || !companyDetails?.company_name) {
            dispatch({
                type: UPLOAD_LOGO_FAILURE,
                payload: "Please fill above fields before uploading dsc"
            })
        } else {
            dispatch(uploadDscFile(
                {
                    "files": [
                        {
                            "file_content": dscFilesData[index]["file_obj"],
                            "object_type": "our_company_dsc",
                            "file_type": "pfx",
                            "file_name": dscFilesData[index]["file_name"],
                            "flag": null,
                            "file_description": "",
                            "company_name": companyDetails?.company_name,
                            "user_id": localStorage.getItem("username"),
                        }
                    ]
                }
            ))
        }
    }

    return (
        <>
            <>
                <NavBarTop />
                <LeftNavBar />
            </>
            <div>
                <div className="addUserContainer">
                    {loading && <FormLoader />}
                    <div className="stepsContainer">
                        <div className="stepsTitle">
                            <h6 style={{ padding: "20px", lineHeight: "0px" }}>Add Our Company</h6>
                        </div>
                        <div className={'stepsCardActive'}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <span style={{ fontWeight: "bold", fontSize: "medium" }}>Company Information</span>
                            </div>
                            <span style={{ fontSize: "small", marginTop: "10px" }}>Our Company Details</span>
                        </div>
                    </div>
                    <div className="infoContainer">
                        <div>
                            <div className="infoTitle">
                                <h6 style={{ padding: "20px", lineHeight: "0px" }}>Company Information</h6>
                            </div>
                            <div className="infoBody">
                                <div className="infoForm">
                                    <div className="d-flex align-items-start justify-content-between flex-wrap gap-3">
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <label className='add-facility-modal-label'>GST Number <span style={{ color: "red" }}>*</span></label>
                                                <input type='text' value={gstNumber} className='form-control' onChange={(e) => handleGstNumberChange(e)} onKeyDown={handleKeyPressForGst} style={{ width: "90%", border: "0.5px solid #9D9D9C" }} />
                                                {!isValidGstNumber && <span className="input-error-text">Invalid GST number</span>}
                                            </div>
                                            {
                                                <div style={{ marginBottom: "0px", display: "flex", alignItems: "start", marginTop: "20px" }}>
                                                    <div
                                                        style={{
                                                            background: "grey",
                                                            padding: "9px 10px",
                                                            marginLeft: "-20px",
                                                            borderRadius: "5px",
                                                            opacity: gstNumber === '' || !isValidGstNumber ? 0.3 : 1,
                                                            pointerEvents: gstNumber === '' || !isValidGstNumber ? 'none' : 'auto'
                                                        }}
                                                        className="C-pointer"
                                                        onClick={() => {
                                                            if (gstNumber !== '' && isValidGstNumber) {
                                                                // Add logic for search when gstNumber is not empty
                                                                getGstDetails();
                                                            }
                                                        }}
                                                    >
                                                        {gstLoader ? (
                                                            <GstLoader />
                                                        ) : (
                                                            <FaSearch color="white" size={20} style={{ fontWeight: "bolder" }} />
                                                        )}
                                                    </div>
                                                </div>

                                            }

                                        </div>
                                        <div>
                                            <label className='add-facility-modal-label'>CIN <span style={{ color: "red" }}>*</span></label>
                                            <input type='text' className='form-control responsive-add-facility-input-fields' value={companyDetails?.cin} onChange={handleCinNumberChange} />
                                            {!isCinValid && <span className='text-danger'>Invalid CIN</span>}
                                        </div>
                                    </div>
                                    <div className="inputsSection">
                                        <div>
                                            <label className='add-facility-modal-label'>Company Name <span style={{ color: "red" }}>*</span></label>
                                            <input type='text' disabled className='form-control responsive-add-facility-input-fields' title={companyDetails?.company_name} value={companyDetails?.company_name} onChange={(e) => setCompanyDetails({ ...companyDetails, company_name: e.target.value })} />
                                        </div>
                                        <div>
                                            <label className='add-facility-modal-label'>Short Name <span style={{ color: "red" }}>*</span></label>
                                            <input type='text' className='form-control responsive-add-facility-input-fields' value={shortName} onChange={(e) => {
                                                let input = eleminateSpacesAtStartOfInputField(e.target.value)
                                                input = eleminateSpecialCharactersAtStartOfInputField(input)
                                                setShortName(input.toUpperCase());
                                                // if (e.target.value.match(/^[a-zA-Z]{4}$/)) {
                                                // }
                                            }} />
                                        </div>
                                        <div>
                                            <label className='add-facility-modal-label'>Email Id <span style={{ color: "red" }}>*</span> </label>
                                            <input type='text' className='form-control responsive-add-facility-input-fields' value={email} onChange={handleEmail} />
                                            <span className='text-danger'>{emailError}</span>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <b className='d-flex my-4'>Gst Primary Address</b>
                                    <div className="inputsSection">
                                        <div>
                                            <label className='add-facility-modal-label'>Door Number <span style={{ color: "red" }}>*</span></label>
                                            <input type='text' className='form-control responsive-add-facility-input-fields' value={companyDetails?.registered_address?.door_number} onChange={(e) => {
                                                setCompanyDetails({ ...companyDetails, registered_address: { ...companyDetails.registered_address, door_number: e.target.value } })
                                            }} />
                                        </div>
                                        <div>
                                            <label className='add-facility-modal-label'>Street Address <span style={{ color: "red" }}>*</span></label>
                                            <input type='text' className='form-control responsive-add-facility-input-fields' value={companyDetails?.registered_address?.street_address_1} onChange={(e) => {
                                                setCompanyDetails({ ...companyDetails, registered_address: { ...companyDetails.registered_address, street_address_1: e.target.value } })
                                            }} />
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>Street Address Lane 2 <span style={{ color: "red" }}>*</span></label>
                                            <input type='text' className='form-control responsive-add-facility-input-fields' value={companyDetails?.registered_address?.street_address_2} onChange={(e) => {
                                                setCompanyDetails({ ...companyDetails, registered_address: { ...companyDetails.registered_address, street_address_2: e.target.value } })
                                            }} />
                                        </div>
                                    </div>
                                    <div className="inputsSection">
                                        <div>
                                            <label className='add-facility-modal-label'>City <span style={{ color: "red" }}>*</span></label>
                                            <input type='text' className='form-control responsive-add-facility-input-fields' value={companyDetails?.registered_address?.city} onChange={(e) => {
                                                setCompanyDetails({ ...companyDetails, registered_address: { ...companyDetails.registered_address, city: e.target.value } })
                                            }} />
                                        </div>
                                        <div>
                                            <label className='add-facility-modal-label'>State <span style={{ color: "red" }}>*</span></label>
                                            <input type='text' className='form-control responsive-add-facility-input-fields' value={companyDetails?.registered_address?.state} onChange={(e) => {
                                                setCompanyDetails({ ...companyDetails, registered_address: { ...companyDetails.registered_address, state: e.target.value } })
                                            }} />
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>Pincode <span style={{ color: "red" }}>*</span></label>
                                            <input type='number' className='form-control responsive-add-facility-input-fields noSpinArrows' 
                                            onWheel={(e) => e.target.blur()}
                                            value={companyDetails?.registered_address?.postal_code} onChange={(e) => {
                                                setCompanyDetails({ ...companyDetails, registered_address: { ...companyDetails.registered_address, postal_code: e.target.value } })
                                            }} />
                                        </div>
                                    </div>
                                    <div className="inputsSection">
                                        <div className='w-100'>
                                            <label className='add-facility-modal-label'>Upload Logo</label>
                                            <UploadComponent
                                                onFilesSelected={selectFile}
                                                uploadActionClicked={uploadLogoData}
                                                maxNumberOfFiles={1}
                                                uploadedFileData={uploadLogoSuccess && uploadLogoSuccess ? [{ file_name: uploadLogoSuccess?.split("/")[2]?.split("-")[0], file_type: uploadLogoSuccess?.split("/")[2]?.split(".")[1], s3_key: uploadLogoSuccess }] : []}
                                                idType={"our_company_add_logo"}
                                                acceptedFileTypes={".jpeg, .jpg, .png"}
                                            />
                                        </div>
                                    </div>
                                    <div className="inputsSection">
                                        <div className='w-100'>
                                            <label className='add-facility-modal-label'>Upload DSC (pfx file) <span style={{ color: "red" }}>*</span> </label>
                                            <UploadComponent
                                                onFilesSelected={selectDscFile}
                                                uploadActionClicked={uploadDsc}
                                                maxNumberOfFiles={1}
                                                uploadedFileData={uploadDscSuccess && uploadDscSuccess ? [{ file_name: uploadDscSuccess?.split("/")[2]?.split("-")[0], file_type: uploadDscSuccess?.split("/")[2]?.split(".")[1], s3_key: uploadDscSuccess }] : []}
                                                idType={"our_company_add_dsc"}
                                                acceptedFileTypes={".pfx"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="infoFotter">
                            <div className="d-flex gap-3">
                                <button className="btn btn-secondary" onClick={() => setShowBackConfirmation(true)}>Back</button>
                                <button className="btn btn-secondary" disabled={
                                    gstNumber === '' || !companyDetails?.company_name || !companyDetails?.cin ||
                                    !companyDetails?.pan_number || !companyDetails?.registered_address?.door_number || !companyDetails?.registered_address?.street_address_1 ||
                                    !companyDetails?.registered_address?.city || !companyDetails?.registered_address?.state || !companyDetails?.registered_address?.postal_code ||
                                    !isValidGstNumber || !isCinValid || shortName === '' || email === '' || emailError !== ''
                                } onClick={addPartnerCompany}>Submit</button>
                            </div>
                        </div>
                    </div>
                    <Modal keyboard={false} backdrop="static" show={showBackConfirmation} onHide={handleBackConfirmation} centered className="popper-modal">
                        <Modal.Body className="text-center">
                            <h5>Are you sure you want to go back?</h5>
                        </Modal.Body>
                        <Modal.Footer className="footer" >
                        <Button variant="secondary" className="confirmation" onClick={handleBackConfirmation} >Cancel</Button>
                        <Button variant="secondary" className="confirmation-left" 
                        onClick={() => {
                                props?.setPage(1)
                                dispatch({
                                    type: UPLOAD_LOGO_SUCCESS,
                                    payload: null
                                })
                            }}> Proceed</Button>
                        </Modal.Footer>
                    </Modal>

                    {getNumberError && <Modal keyboard={false} backdrop="static" show={getNumberError} onHide={() => {
                        setGetNumberError(null)
                    }
                    } centered className="popper-modal">
                        <Modal.Body className="text-center">
                            <MdErrorOutline color='red' style={{ height: "60px", width: "60px", fontWeight: "10px" }} />
                            <h5>Error!</h5>
                            <p className="mb-3">{getNumberError}</p>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-center" >
                            <Button variant="secondary" className="secondary" onClick={() => {
                                setGetNumberError(null)
                            }
                            } >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>}
                    {createPartnerCompanySuccess && <Modal keyboard={false} backdrop="static" show={createPartnerCompanySuccess} onHide={() => {
                        dispatch({
                            type: CREATE_PARTNER_COMPANY_SUCCESS,
                            payload: null
                        })
                    }} centered className="popper-modal">
                        <Modal.Body className="text-center">
                            <img src="./images/icons/popper.svg" alt="Party Popper" />
                            <h5>Success!</h5>
                            <p className="mb-3"><b>{companyDetails?.company_name}</b> {createPartnerCompanySuccess}</p>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-center" >
                            <Button variant="secondary" className="secondary" onClick={() => {
                                dispatch({
                                    type: CREATE_PARTNER_COMPANY_SUCCESS,
                                    payload: null
                                })
                                dispatch({
                                    type: UPLOAD_LOGO_SUCCESS,
                                    payload: null
                                })
                                dispatch({
                                    type: UPLOAD_DSC_SUCCESS,
                                    payload: null
                                })
                                props?.setNavigate(!props?.navigat)
                                props?.setPage(1)
                            }} >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>}
                    {createPartnerCompanyError && <Modal keyboard={false} backdrop="static" show={createPartnerCompanyError} onHide={() => {
                        dispatch({
                            type: CREATE_PARTNER_COMPANY_FAILURE,
                            payload: null
                        })
                    }} centered className="popper-modal">
                        <Modal.Body className="text-center">
                            <MdErrorOutline color='red' style={{ height: "60px", width: "60px", fontWeight: "10px" }} />
                            <h5>Error!</h5>
                            <p className="mb-3">{createPartnerCompanyError}</p>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-center" >
                            <Button variant="secondary" className="secondary" onClick={() => {
                                dispatch({
                                    type: CREATE_PARTNER_COMPANY_FAILURE,
                                    payload: null
                                })
                            }} >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>}
                    {uploadLogoError && <Modal keyboard={false} backdrop="static" show={uploadLogoError} onHide={() => {
                        dispatch({
                            type: UPLOAD_LOGO_FAILURE,
                            payload: null
                        })
                    }} centered className="popper-modal">
                        <Modal.Body className="text-center">
                            <MdErrorOutline color='red' style={{ height: "60px", width: "60px", fontWeight: "10px" }} />
                            <h5>Error!</h5>
                            <p className="mb-3">{uploadLogoError}</p>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-center" >
                            <Button variant="secondary" className="secondary" onClick={() => {
                                dispatch({
                                    type: UPLOAD_LOGO_FAILURE,
                                    payload: null
                                })
                            }} >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>}
                    {uploadDscError && <AlertPopUp alertShow={uploadDscError} alertType="error" alertMsg="Error" alertValue={uploadDscError} alertButtons={[{ name: 'Close', action: () => { dispatch({ type: UPLOAD_LOGO_FAILURE, payload: null }) } }]} />}
                    <AlertPopUp alertShow={alertShow} alertType={alertType} alertMsg={alertMessage} alertValue={alertValue} alertButtons={alertButtons} />
                </div>
            </div>
        </>
    )
}


const GstLoader = () => {
    return <Spinner style={{ height: "18px", width: "18px" }} animation="border" variant="light" />
}