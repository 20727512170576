import React from 'react'

export default function Task({ task, handleOpenTask }) {

    return (
        <div className='task-item C-pointer' onClick={() => handleOpenTask(task)}>
            <div style={{ borderBottom: "1px solid #CFCFCF", width: "100%" }}>{task?.eventName}</div>
            <div className='d-flex justify-content-between mt-2'>
                <div className='d-flex gap-2'>
                    <img src='./images/icons/schedule.svg' />
                    <span>{task?.scheduledDate}</span>
                </div>
                <span>{task?.scheduledTime}</span>
            </div>
            <div className='d-flex gap-2 align-items-center mt-2'>
                <img src="./images/icons/Person.svg" alt="person" />
                <span style={{ backgroundColor: "#F3F3F3", width: "fit-content" }} className='p-1'>
                    {task?.assignedTo}
                </span>
            </div>
            <div className='d-flex gap-3 align-items-center mt-2'>
                <img src="./images/icons/facility.svg" alt="location" height={18} width={18} />
                <span style={{ backgroundColor: task?.facilityColor, width: "fit-content" }} className='p-1'>{task?.facility}</span>
            </div>
        </div>
    )
}
