import React, { useEffect, useState } from 'react'
import { RxPencil2 } from 'react-icons/rx'
import { IoIosClose } from "react-icons/io";
import { BsCheck2 } from "react-icons/bs";

export default function Table({ object, handleTabelData, handleFormData, preview, tableData, data, rowIndex, colIndex, formUse }) {


    const [tableobject, setTableObject] = useState({})

    useEffect(() => {
        preview && preview ? setTableObject(object) :
            setTableObject({
                ...object, tableData: object?.tableData?.map((data) => {
                    return data?.map((each) => {
                        return { ...each, edit: false }
                    })
                })
            })
    }, [object, object.columnNames, object.columns, object.tableData, object.columnNames])

    useEffect(() => {
        formUse && handleFormData && handleFormData(object.content + "-" + rowIndex + colIndex, object.tableData.map((data, i) => {
            let obj = {}
            obj["row"] = i + 1
            object.columnsType.forEach((type, index) => {
                if (type === "toggle") {
                    obj[object.columnNames[index]] = false
                } else if (type === "input") {
                    obj[object.columnNames[index]] = ""
                } else {
                    obj[object.columnNames[index]] = data[index]["content"]
                }
            })
            return obj
        }))
    }, [])

    const handleEdit = (rowIndex, columnIndex) => {
        const tableData = { ...tableobject }
        setTableObject({
            ...tableobject, tableData: tableData.tableData.map((data, index) => {
                return index === rowIndex ? data.map((each, i) => {
                    return i === columnIndex ? { ...each, edit: true } : each
                }) : data
            })
        })
    }

    const handleChangeData = (e, rowIndex, columnIndex) => {
        const tableData = { ...tableobject }
        setTableObject({
            ...tableobject, tableData: tableData.tableData.map((data, index) => {
                return index === rowIndex ? data.map((each, i) => {
                    return i === columnIndex ? { ...each, content: e.target.value } : each
                }) : data
            })
        })
    }

    const handleCancel = (rowIndex, columnIndex) => {
        // tableData.tableData[rowIndex][columnIndex].edit = false
        setTableObject({
            ...tableobject, tableData: tableobject.tableData.map((data, index) => {
                return index === rowIndex ? data.map((each, i) => {
                    return i === columnIndex ? { ...each, edit: false, content: "text" } : each
                }) : data
            })
        })
    }
    const handleSave = (rowIndex, columnIndex) => {
        // tableData.tableData[rowIndex][columnIndex].edit = false
        handleTabelData(tableobject.id, tableobject.tableData)
        setTableObject({
            ...tableobject, tableData: tableobject.tableData.map((data, index) => {
                return index === rowIndex ? data.map((each, i) => {
                    return i === columnIndex ? { ...each, edit: false } : each
                }) : data
            })
        })
    }

    return (
        <div className='form_table_container'>
            <table style={{ width: "100%" }}>
                <thead className='form_table_header'>
                    <tr>
                        {tableobject?.columnNames?.map((columnName, index) => {
                            return <td key={index} style={{ border: "0.4px solid #D3D3D3", fontSize: `14px`, textWrap: "wrap" }} >{columnName}</td>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {tableobject?.tableData?.map((row, index) => {
                        return <tr key={index}>
                            {row.map((column, i) => {
                                return <td style={{ width: `fit-content`, maxWidth: "75%", border: `0.4px solid #D3D3D3`, fontSize: `14px`, padding: "8px" }} key={i} >{object.columnsType[i] === "text" ?
                                    !preview && column.edit ?
                                        <div className='d-flex gap-1 align-items-center'>
                                            <input type='text' value={column.content} onChange={(e) => handleChangeData(e, index, i)} className='form-control' />
                                            <BsCheck2 color='green' style={{ height: "20px", width: "20px" }} onClick={() => handleSave(index, i)} />
                                            <IoIosClose color='red' style={{ height: "30px", width: "30px" }} onClick={() => handleCancel(index, i)} />
                                        </div> :
                                        <FixedText index={index} i={i} preview={preview} value={column.content} handleEdit={handleEdit} />
                                    : object.columnsType[i] === "toggle" ?
                                        <Toggle tableData={tableData && tableData} keyName={tableobject.columnNames[i]}
                                            tableName={tableobject.content}
                                            row={index}
                                            rowIndex={rowIndex && rowIndex}
                                            colIndex={colIndex && colIndex}
                                            data={data && data[object?.content + "-" + rowIndex + colIndex] && data[object?.content + "-" + rowIndex + colIndex][index][object.columnNames[i]]}
                                        /> :
                                        <TextInput tableData={tableData && tableData} keyName={tableobject.columnNames[i]}
                                            tableName={tableobject.content}
                                            row={index}
                                            rowIndex={rowIndex && rowIndex}
                                            colIndex={colIndex && colIndex}
                                            data={data && data[object?.content + "-" + rowIndex + colIndex] && data[object?.content + "-" + rowIndex + colIndex][index][object.columnNames[i]]}
                                        />}
                                </td>
                            })}
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}

const FixedText = (props) => {
    return <div onClick={() => !(props.preview) && props.handleEdit(props.index, props.i)}>{props.value}</div>
}

const TextInput = ({ tableData, keyName, row, tableName, rowIndex, colIndex, data }) => {
    return data ? <span style={{ fontSize: "12px", color: "gray", textWrap: "wrap" }}>{data}</span> : <input className='form-control'
        value={data && data}
        disabled={data?.length >= 0}
        onChange={(e) => tableData && tableData(keyName, row, tableName + "-" + rowIndex + colIndex, e.target.value)}
    />
}

const Toggle = ({ tableData, keyName, row, tableName, rowIndex, colIndex, data }) => {
    return <div className="form-switch d-flex">
        <input className="form-check-input" style={{ width: "40px", height: "20px" }} type="checkbox" id="paymentTerms"
            checked={data && data}
            disabled={data}
            onChange={(e) => tableData && tableData(keyName, row, tableName + "-" + rowIndex + colIndex, e.target.checked)} />
    </div>
}