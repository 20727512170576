import React,{useEffect} from 'react'

export default function Toggle({object,handleFormData,formUse,rowIndex,colIndex,data}) {

    useEffect(() => {
        formUse && handleFormData && handleFormData(object.content+"-"+rowIndex+colIndex,false)
    },[])

    return (
        <div className='d-flex gap-3 align-items-center justify-content-between' style={{padding:"8px"}}>
            <label>{object.content}</label>
            <div className="form-switch d-flex">
                <input className="form-check-input" name={object.content} 
                checked={data && data[object.content+"-"+rowIndex+colIndex]}
                disabled={data && data}
                style={{ width: "40px", height: "20px" }} type="checkbox" id="paymentTerms" 
                onChange={(e) => handleFormData && handleFormData(object.content+"-"+rowIndex+colIndex,e.target.checked)} />
            </div>
        </div>
    )
}
