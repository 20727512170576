import React, { useEffect } from 'react'

export default function Dropdown({ object, handleFormData, formUse, rowIndex, colIndex, data }) {

  useEffect(() => {
    formUse && handleFormData && handleFormData(object.content + "-" + rowIndex + colIndex, "")
  }, [])

  return (
    <div className='d-flex align-items-center justify-content-between gap-4 w-100' style={{ padding: "8px" }}>
      <label>{object.content}</label>
      {data && data ? <span style={{ borderRadius: "8px", width: "60%",fontSize:"12px",color:"gray" }}>{data[object.content + "-" + rowIndex + colIndex]}</span> :<select className='form-control p-2' style={{ borderRadius: "8px", width: "60%" }}
        value={data && data[object.content + "-" + rowIndex + colIndex]}  
        disabled={data && data}
        onChange={(e) => handleFormData && handleFormData(object.content + "-" + rowIndex + colIndex, e.target.value)}>
        <option hidden>Select</option>
        {object.options.map((option, index) => {
          return <option key={index} value={option}>{option}</option>
        })}
      </select>}
    </div>
  )
}
