import React, { useEffect, useState, useRef } from "react";
import NavBarTop from "../Reusable_Components/NavBarTop";
import LeftNavBar from "../Reusable_Components/LeftNavBar";
import { GoSearch } from "react-icons/go";
import Pagination from "../Reusable_Components/Pagination";
import { RECORDS_PER_PAGE } from "../../Config";
import TableComponent from "../Reusable_Components/TableComponent";
import Footer from "../Login/Footer";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UploadComponent from '../Reusable_Components/UploadComponent';
import { eleminateSpacesAtStartOfInputField, processAndValidateEmail,
    checkUniqueness
 } from "../Reusable_Components/ReusableFunctions";
import {facilityManagerGetAllAssets, handlePaginationLoaderTrue} from '../../Redux/Actions/Actions'
import { useDispatch,useSelector } from "react-redux";
import { Button } from "react-bootstrap";



export default function FacilityManagerAssetsList() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(facilityManagerGetAllAssets({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: 1 }))
    }, [dispatch])
    

    const assetListDummyData = [
        {
            "asset_name": "Generator A",
            "asset_category": "Electrical",
            "asset_id": "A123",
            "unit_name": "Unit 1",
            "facility_name": "Facility Alpha",
            "installation_date": "2020-05-15",
            "ams_start_date": "2021-01-01",
            "ams_end_date": "2023-12-31",
            "created_by": "John Doe",
            "last_updated_at": "2024-08-12T10:15:30Z"
        },
        {
            "asset_name": "AC Unit B",
            "asset_category": "HVAC",
            "asset_id": "B456",
            "unit_name": "Unit 3",
            "facility_name": "Facility Beta",
            "installation_date": "2019-09-20",
            "ams_start_date": "2020-07-01",
            "ams_end_date": "2022-07-01",
            "created_by": "Jane Smith",
            "last_updated_at": "2024-08-14T14:22:45Z"
        },
        {
            "asset_name": "Solar Panel C",
            "asset_category": "Renewable Energy",
            "asset_id": "C789",
            "unit_name": "Unit 4",
            "facility_name": "Facility Gamma",
            "installation_date": "2021-12-10",
            "ams_start_date": "2022-02-15",
            "ams_end_date": "2025-02-15",
            "created_by": "Robert Lee",
            "last_updated_at": "2024-08-16T09:10:05Z"
        },
        {
            "asset_name": "Elevator D",
            "asset_category": "Mechanical",
            "asset_id": "D321",
            "unit_name": "Unit 2",
            "facility_name": "Facility Delta",
            "installation_date": "2020-07-25",
            "ams_start_date": "2021-03-01",
            "ams_end_date": "2024-03-01",
            "created_by": "Alice Cooper",
            "last_updated_at": "2024-08-18T11:30:20Z"
        },
        {
            "asset_name": "Boiler E",
            "asset_category": "Heating",
            "asset_id": "E654",
            "unit_name": "Unit 5",
            "facility_name": "Facility Epsilon",
            "installation_date": "2018-04-18",
            "ams_start_date": "2019-05-01",
            "ams_end_date": "2022-05-01",
            "created_by": "Frank Wright",
            "last_updated_at": "2024-08-19T16:45:55Z"
        },
        {
            "asset_name": "Transformer F",
            "asset_category": "Electrical",
            "asset_id": "F987",
            "unit_name": "Unit 6",
            "facility_name": "Facility Zeta",
            "installation_date": "2019-11-22",
            "ams_start_date": "2020-06-15",
            "ams_end_date": "2023-06-15",
            "created_by": "Emily Clark",
            "last_updated_at": "2024-08-20T08:50:40Z"
        },
        {
            "asset_name": "Chiller G",
            "asset_category": "Cooling",
            "asset_id": "G852",
            "unit_name": "Unit 7",
            "facility_name": "Facility Eta",
            "installation_date": "2020-02-05",
            "ams_start_date": "2021-01-01",
            "ams_end_date": "2024-01-01",
            "created_by": "George Lopez",
            "last_updated_at": "2024-08-22T13:12:30Z"
        },
        {
            "asset_name": "Lighting H",
            "asset_category": "Lighting",
            "asset_id": "H159",
            "unit_name": "Unit 8",
            "facility_name": "Facility Theta",
            "installation_date": "2022-08-10",
            "ams_start_date": "2023-01-01",
            "ams_end_date": "2026-01-01",
            "created_by": "Sophia Turner",
            "last_updated_at": "2024-08-24T15:23:10Z"
        },
        {
            "asset_name": "Cooling Tower I",
            "asset_category": "HVAC",
            "asset_id": "I753",
            "unit_name": "Unit 9",
            "facility_name": "Facility Iota",
            "installation_date": "2019-01-19",
            "ams_start_date": "2019-10-01",
            "ams_end_date": "2022-10-01",
            "created_by": "Charles Wilson",
            "last_updated_at": "2024-08-25T10:05:50Z"
        },
        {
            "asset_name": "Water Pump J",
            "asset_category": "Plumbing",
            "asset_id": "J456",
            "unit_name": "Unit 10",
            "facility_name": "Facility Kappa",
            "installation_date": "2021-06-15",
            "ams_start_date": "2022-01-01",
            "ams_end_date": "2025-01-01",
            "created_by": "Megan Brown",
            "last_updated_at": "2024-08-26T09:30:15Z"
        }
    ]
    const [activeTab, setActiveTab] = useState('1');
    // Function to handle the next button click
  const handleNext = () => {
    switch (activeTab) {
      case '1':
        setActiveTab('2');
        break;
      case '2':
        setActiveTab('3');
        break;
      case '3':
        setActiveTab('4');
        break;
      default:
        setActiveTab('1'); // Optionally loop back to the first tab
    }
  };

  // Function to handle the back button click
  const handleBack = () => {
    switch (activeTab) {
      case '4':
        setActiveTab('3');
        break;
      case '3':
        setActiveTab('2');
        break;
      case '2':
        setActiveTab('1');
        break;
      default:
        setActiveTab('4'); // Optionally loop back to the last tab
    }
  };
    
  const [searchText, setSearchText] = useState("");
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  }
  const currentPaginationPage = useSelector(state => state.allAssetsList?.info?.page_number);
  const [currentPage, setCurrentPage] = useState(1);
  const [assetName, setAssetName] = useState("");
  const [viewClicked, setViewClicked] = useState(false);
  const [selectVendorSupplier, setSelectVendorSupplier] = useState("");

  const [selectServiceProvider, setSelectServiceProvider] = useState("");

  const handleServiceProviderChange = (e) => {
    setSelectServiceProvider(e.target.value);
  }

    const handleVendorSupplierChnge = (e) => {
        setSelectVendorSupplier(e.target.value);
    }

  const allAssetsListTableData = useSelector(state => state.allAssetsList?.data)
  const totalRecords = useSelector((state) => state.allAssetsList?.info?.total_no_of_records);

  const handleAssetNameChange = (e) => {
    setAssetName(e.target.value);
  };

 

  const handledPaginationNextClicked = () => {
    // Define the functionality for handling the next pagination click
    handlePaginationLoaderTrue()
    dispatch(facilityManagerGetAllAssets({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: currentPaginationPage + 1 }))
  };

  const handledPaginationPrevClicked = () => {
    // Define the functionality for handling the previous pagination click
    dispatch(facilityManagerGetAllAssets({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: currentPaginationPage - 1 }))

  };

  const handleActionClicked = (data) => {
    // Define the functionality for handling the action click
  };


  const handleAddNewAsset = () => {
    setCurrentPage(2);
  }

//   ======================= current page 2 fields and functions ===============================

// =================================== Asset Details ==================================

  const [facilityName, setFacilityName] = useState("");
  const [spaceName, setSpaceName] = useState("");
    const [assetCategory, setAssetCategory] = useState("");
    const [assetType, setAssetType] = useState("");
    const [assetId, setAssetId] = useState("");
    const [installationDate, setInstallationDate] = useState("");
    const [purchaseDate, setPurchaseDate] = useState("");
    const [brand, setBrand] = useState("");
    const [status, setStatus] = useState("");
    const [description, setDescription] = useState("");
    const [newAssetCategory, setNewAssetCategory] = useState("");

    const handleNewAssetCategoryChange = (e) => {
        setNewAssetCategory(e.target.value);
        };

    const handleFacilityNameChange = (e) => {
        setFacilityName(e.target.value);
      };
      
      const handleSpaceNameChange = (e) => {
        setSpaceName(e.target.value);
      };
      
      const handleAssetCategoryChange = (e) => {
        setAssetCategory(e.target.value);
      };
      
      const handleAssetTypeChange = (e) => {
        setAssetType(e.target.value);
      };
      
      const handleAssetIdChange = (e) => {
        setAssetId(e.target.value);
      };
      
      const handleInstallationDateChange = (e) => {
        setInstallationDate(e.target.value);
      };
      
      const handlePurchaseDateChange = (e) => {
        setPurchaseDate(e.target.value);
      };
      
      const handleBrandChange = (e) => {
        setBrand(e.target.value);
      };
      
      const handleStatusChange = (e) => {
        setStatus(e.target.value);
      };
      
      const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
      };

// =================================== Asset Details ==================================

// =================================== Purchase Details ==================================

    const [purchaseOrderNo, setPurchaseOrderNo] = useState("");
    const [assetCost, setAssetCost] = useState("");
    const [vendorName, setVendorName] = useState("");
    const [vendorContactNumber, setVendorContactNumber] = useState("");
    const [vendorEmail, setVendorEmail] = useState("");
    const [gstNumberVendor, setGstNumberVendor] = useState('');
    const [cinNumberVendor, setCinNumberVendor] = useState('');

    const [companyNameVendor, setCompanyNameVendor] = useState("");
    const [panNameVendor, setPanNameVendor] = useState("");
    const [registeredStreetAddressVendor, setRegisteredStreetAddressVendor] = useState("");
    const [registeredStreetAddressLane2Vendor, setRegisteredStreetAddressLane2Vendor] = useState("");
    const [registeredCityVendor, setRegisteredCityVendor] = useState("");
    const [registeredStateProvinceVendor, setRegisteredStateProvinceVendor] = useState("");
    const [registeredPostalCodeVendor, setRegisteredPostalCodeVendor] = useState("");

    const [billingStreetAddressVendor, setBillingStreetAddressVendor] = useState("");
    const [billingStreetAddressLane2Vendor, setBillingStreetAddressLane2Vendor] = useState("");
    const [billingCityVendor, setBillingCityVendor] = useState("");
    const [billingStateProvinceVendor, setBillingStateProvinceVendor] = useState("");
    const [billingPostalCodeVendor, setBillingPostalCodeVendor] = useState("");

    const [shippingStreetAddressVendor, setShippingStreetAddressVendor] = useState("");
    const [shippingStreetAddressLane2Vendor, setShippingStreetAddressLane2Vendor] = useState("");
    const [shippingCityVendor, setShippingCityVendor] = useState("");
    const [shippingStateProvinceVendor, setShippingStateProvinceVendor] = useState("");
    const [shippingPostalCodeVendor, setShippingPostalCodeVendor] = useState("");

    const handleShippingStreetAddressVendorChange = (e) => {
        setShippingStreetAddressVendor(e.target.value);
    };

    const handleShippingStreetAddressLane2VendorChange = (e) => {
        setShippingStreetAddressLane2Vendor(e.target.value);
    };

    const handleShippingCityVendorChange = (e) => {
        setShippingCityVendor(e.target.value);
    };

    const handleShippingStateProvinceVendorChange = (e) => {
        setShippingStateProvinceVendor(e.target.value);
    };

    const handleShippingPostalCodeVendorChange = (e) => {
        setShippingPostalCodeVendor(e.target.value);
    };



    const handleCompanyNameVendorChange = (e) => {
        setCompanyNameVendor(e.target.value);
    };
    
    const handlePanNameVendorChange = (e) => {
        setPanNameVendor(e.target.value);
    };
    
    const handleRegisteredStreetAddressVendorChange = (e) => {
        setRegisteredStreetAddressVendor(e.target.value);
    };
    
    const handleRegisteredStreetAddressLane2VendorChange = (e) => {
        setRegisteredStreetAddressLane2Vendor(e.target.value);
    };
    
    const handleRegisteredCityVendorChange = (e) => {
        setRegisteredCityVendor(e.target.value);
    };
    
    const handleRegisteredStateProvinceVendorChange = (e) => {
        setRegisteredStateProvinceVendor(e.target.value);
    };
    
    const handleRegisteredPostalCodeVendorChange = (e) => {
        setRegisteredPostalCodeVendor(e.target.value);
    };
    
    const handleBillingStreetAddressVendorChange = (e) => {
        setBillingStreetAddressVendor(e.target.value);
    };
    
    const handleBillingStreetAddressLane2VendorChange = (e) => {
        setBillingStreetAddressLane2Vendor(e.target.value);
    };
    
    const handleBillingCityVendorChange = (e) => {
        setBillingCityVendor(e.target.value);
    };
    
    const handleBillingStateProvinceVendorChange = (e) => {
        setBillingStateProvinceVendor(e.target.value);
    };
    
    const handleBillingPostalCodeVendorChange = (e) => {
        setBillingPostalCodeVendor(e.target.value);
    };
    


    const handlePurchaseOrderChange = (e) => {
        setPurchaseOrderNo(e.target.value);
    };
    
    const handleAssetCostChange = (e) => {
        setAssetCost(e.target.value);
    };
    
    const handleVendorNameChange = (e) => {
        setVendorName(e.target.value);
    };
    
    const handleVendorContactNumberChange = (e) => {
        setVendorContactNumber(e.target.value);
    };
    
    const handleVendorEmailChange = (e) => {
        setVendorEmail(e.target.value);
    };
    
    const handleGstNumberVendorChange = (e) => {
        setGstNumberVendor(e.target.value);
    };
    const handleCinNumberVendorChange = (e) => {
        setCinNumberVendor(e.target.value);
    };





    const [listOfContacts, setListOfContacts] = useState([{
        name: "",
        phone_number: "",
        email: "",
        designation: "",
    }])

    const [listOfContactsValidation, setListOfContactsValidation] = useState([{
        isContactNameValid: true,
        isDesignationValid: true,
        isValidPhone: true,
        isValidEmail: true,
        isEmailNotDuplicate: true
    }])

    const handleAddAnotherContactValidation = () => {
        setListOfContactsValidation([...listOfContactsValidation, {
            isContactNameValid: true,
            isDesignationValid: true,
            isValidPhone: true,
            isValidEmail: true,
            isEmailNotDuplicate: true
        }])

    }

    const handleAddAnotherContact = () => {
        setListOfContacts([...listOfContacts, {
            name: "",
            phone_number: "",
            email: "",
            designation: "",
        }])

    }

    const deleteSpecificContact = (id, contact) => {

        // if (editCompleteContractClicked) {
        //     setShowDeleteContractContact(true)
        //     setDeleteContractContactSelected(contact)
        //     setDeleteContractContactIdSelected(id)
        // }
        // else {
            const newArray = listOfContacts.filter((_, index) => index !== id);
            const newVaildArray = listOfContactsValidation.filter((_, index) => index !== id);
            setListOfContacts([...newArray]);
            setListOfContactsValidation([...newVaildArray])
        // }


    }



// =================================== Purchase Details ==================================


    //   ======================= current page 2 fields and functions ===============================

    const handleEmailUpdateChange = (e, index) => {

        const input = e.target.value;
        const currentEmail = listOfContactEmails[index] ? listOfContactEmails[index] : '';
        const { processedInput, isValid } = processAndValidateEmail(input, currentEmail);

        const updatedContacts = [...listOfContacts];
        const updatedContactsValidation = [...listOfContactsValidation];
        const isUnique = checkUniqueness(listOfContactEmails, currentEmail, processedInput);

        updatedContacts[index] = { ...updatedContacts[index], email: processedInput };
        updatedContactsValidation[index] = { ...updatedContactsValidation[index], isValidEmail: isValid, isEmailNotDuplicate: !isUnique };

        setListOfContacts(updatedContacts);
        setListOfContactsValidation(updatedContactsValidation);
    };

    const [listOfContactEmails, setListOfContactEmails] = useState([]);

    const onEmailInputBlur = (e) => {
        const emailArray = []
        listOfContacts.forEach((contact) => {
            if (!emailArray.includes(contact.email) && contact.email !== "") {
                emailArray.push(contact.email)
            }
        })
        setListOfContactEmails([...emailArray])
    }


      

  return (
    <>
            <>
                <NavBarTop />
                <LeftNavBar />
            </>
            {
                currentPage === 1 ? (
            <div className='meter_reports_container'>
                    <div className='d-flex justify-content-between align-items-center flex-wrap'>
                        <div className='d-flex align-items-center gap-3 mb-3'>
                            <h3 className="invoice-heading" >Assets</h3>
                        </div>
                        <div className='d-flex align-items-center gap-3 mb-3'>
                            <div className='fin_man_meter_search_bar_container'>
                                <input type='search' placeholder='Search' value={searchText} className='fin_man_meter_search_bar' 
                                onChange={handleSearchChange} 
                                />
                                <GoSearch className='fin_man_meter_search_bar_icon' />
                            </div>
                            <button className='invoice_btn'
                                onClick={() => handleAddNewAsset()} 
                                style={{ color: "white" }}>
                                    <span className='generate_invoice'><img src={"./images/icons/newContract.svg"} style={{ marginRight: "10px" }} alt="+" />
                                Add New Asset</span><span className='generate'><img src={"./images/icons/newContract.svg"} style={{ marginRight: "10px" }} alt="+" />Add</span>
                                </button>
                        </div>
                    </div>

                    <div className='table_container_commercial_head'>
                        <TableComponent
                            headers = {[{
                                name: "Asset Name",
                                type: "text",
                                key: "asset_name",
                                sort: true
                            }, {
                                name: "Asset Category",
                                type: "text",
                                key: "asset_category",
                                sort: true
                            }, {
                                name: "Asset Id",
                                type: "text",
                                key: "asset_id",
                                sort: true
                            }, {
                                name: "Location",
                                type: "text",
                                key: "space",
                                sort: true
                            }, 
                            // {
                            //     name: "Installation Date",
                            //     type: "date",
                            //     key: "installation_date",
                            //     sort: true
                            // }, {
                            //     name: "AMS Start Date",
                            //     type: "date",
                            //     key: "ams_start_date",
                            //     sort: true
                            // }, {
                            //     name: "AMS End Date",
                            //     type: "date",
                            //     key: "ams_end_date",
                            //     sort: true
                            // }, 
                            {
                                name: "Created By",
                                type: "text",
                                key: "created_by",
                                sort: true
                            }, {
                                name: "Last Updated At",
                                type: "time_stamp",
                                key: "updated_at",
                                sort: true
                            }, 
                            {
                                name: "Action",
                                type: "button",
                                buttons: [{
                                    name: "View",
                                    icon: "./images/icons/ViewViewIconEye.svg",
                                    action: () => handleActionClicked([])
                                }]
                            }] }

                            data={allAssetsListTableData}
                            />
                            
                    </div>
                    <Pagination
                        handledPaginationNextClicked={handledPaginationNextClicked}
                        handledPaginationPrevClicked={handledPaginationPrevClicked}
                        isApplyClicked
                        totalRecords={totalRecords}
                        recordsPerPage={RECORDS_PER_PAGE}
                        currentPaginationPage={currentPaginationPage}
                    />
                </div>
                ) : null
            }

            {
                currentPage === 2 ? (
                    <div className="newContractContainer">
                        <div style={{display:"flex", flexDirection:"column", margin:'10px', width:"100vw"}}>
                            <div>
                                <h2>Add New Asset</h2>
                            </div>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <label className='add-facility-modal-label'>Asset Name <span style={{ color: "red" }}>*</span></label>
                                <input type='text' value={assetName} 
                                // disabled={viewClicked || editCompleteContractClicked}
                                    
                                    className='form-control'    
                                    disabled
                                    style={{ width: "100%" }}
                                     />
                            </div>
                            <div style={{marginTop:"20px"}}>
                                <Tabs
                                    activeKey={activeTab}
                                    id="uncontrolled-tab-example"
                                    className="mb-0"
                                    >
                                    <Tab eventKey="1" title="Asset Details" className="assetTabs" disabled>
                                        <div className="infoBody">
                                            <div>
                                                <h7 style={{ fontWeight: "bold" }}>Location of the Asset</h7>
                                                <div className="infoBodyInputs">
                                                    <div className="responsiveInputSection">
                                                        <div >
                                                            <label className='add-facility-modal-label' 
                                                            // disabled={viewClicked}
                                                            >Facility Name {<span style={{ color: "red" }}>*</span>}</label>
                                                            <select 
                                                            // disabled={viewClicked} 
                                                            value={facilityName} 
                                                            onChange={handleFacilityNameChange} 
                                                            className='form-select responsive-add-facility-input-fields'>
                                                                <option value="inactive">Facility 1</option>
                                                                <option value="active">Facility 2</option>
                                                                <option value="pending">Facility 3</option>
                                                            </select>
                                                        </div>
                                                        <div >
                                                            <label className='add-facility-modal-label' 
                                                            // disabled={viewClicked}
                                                            >Space Name {<span style={{ color: "red" }}>*</span>}</label>
                                                            <select 
                                                            // disabled={viewClicked} 
                                                            value={spaceName} 
                                                            onChange={handleSpaceNameChange} 
                                                            className='form-select responsive-add-facility-input-fields'>
                                                                <option value="inactive">Space 1</option>
                                                                <option value="active">Space 2</option>
                                                                <option value="pending">Space 3</option>
                                                            </select>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div>
                                                <h7 style={{ fontWeight: "bold" }}>Asset Details</h7>
                                                <div className="infoBodyInputs">
                                                    <div className="responsiveInputSection" style={{justifyContent: "space-between"}}>
                                                        <div >
                                                            <label className='add-facility-modal-label' 
                                                            // disabled={viewClicked}
                                                            >Asset Category {<span style={{ color: "red" }}>*</span>}</label>
                                                            <select 
                                                            // disabled={viewClicked} 
                                                            value={assetCategory} 
                                                            onChange={handleAssetCategoryChange} 
                                                            className='form-select responsive-add-facility-input-fields'>
                                                                <option value="inactive">Street Light</option>
                                                                <option value="active">Genrators</option>
                                                                <option value="pending">Fire Alarm System</option>
                                                                <option 
                                                                style={{backgroundColor:"#C6C6C6", color:"black", margin:"10px"}} 
                                                                value="addAssetCategory"> <span >+ Add Category</span></option>
                                                            </select>
                                                        </div>
                                                        {
                                                            assetCategory === "addAssetCategory" ? (
                                                                <div style={{display:"flex", flexDirection:"row"}}>
                                                                <div>
                                                                    <label className='add-facility-modal-label'>Add Asset Category <span style={{ color: "red" }}>*</span></label>
                                                                    <input type='text'
                                                                    value={newAssetCategory}
                                                                    
                                                                    onChange={handleNewAssetCategoryChange}
                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div style={{display:"flex", alignItems:"end"}}>
                                                                        <button className='form-control' style={{height:"70%", backgroundColor:"#B2B2B2"}}>+ ADD</button>
                                                                </div>
                                                        </div>
                                                            ) : null
                                                        }
                                                        
                                                        <div>
                                                        
                                                        <label className='add-facility-modal-label' 
                                                            // disabled={viewClicked}
                                                            >Status {<span style={{ color: "red" }}>*</span>}</label>
                                                            <select 
                                                            // disabled={viewClicked} 
                                                            value={status} 
                                                            onChange={handleStatusChange} 
                                                            className='form-select responsive-add-facility-input-fields'>
                                                                <option value="inactive">Active</option>
                                                                <option value="active">Repair</option>
                                                                <option value="pending">Decommissioned</option>
                                                                <option value="pending1">Inactive</option>
                                                                <option style={{backgroundColor:"#C6C6C6", color:"black", margin:"10px"}}value="addAssetCategory"> <span >+ Add Category</span></option>
                                                            </select>

                                                        </div>
                                                        
                                                    </div>
                                                        
                                                    <div className="responsiveInputSection">
                                                        <div >
                                                            <label className='add-facility-modal-label'>Asset Name <span style={{ color: "red" }}>*</span></label>
                                                            <input type='text'
                                                            value={assetName}
                                                            onChange={handleAssetNameChange}
                                                                className='form-control responsive-add-facility-input-fields' />
                                                        </div>
                                                        <div>
                                                            <label className='add-facility-modal-label'>Asset Id<span style={{ color: "red" }}>*</span></label>
                                                            <input 
                                                            type='text' 
                                                            value={assetId}
                                                            onChange={handleAssetIdChange}
                                                                className='form-control responsive-add-facility-input-fields' />
                                                            
                                                        </div>
                                                        <div >
                                                            <label className='add-facility-modal-label' 
                                                            // disabled={viewClicked}
                                                            >Asset Type {<span style={{ color: "red" }}>*</span>}</label>
                                                            <select 
                                                            // disabled={viewClicked}
                                                            value={assetType}
                                                            onChange={handleAssetTypeChange}
                                                            className='form-select responsive-add-facility-input-fields'>
                                                                <option value="inactive">Current</option>
                                                                <option value="active">Type 2</option>
                                                                <option value="pending">Type 3</option>
                                                            </select>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="responsiveInputSection">
                                        
                                                        <div >
                                                            <label className='add-facility-modal-label'>Date of Installation { <span style={{ color: "red" }}>*</span>}</label>
                                                            <input 
                                                            // disabled={viewClicked} 
                                                            type='date'

                                                                min={new Date()}
                                                                value={installationDate}
                                                                onChange={handleInstallationDateChange}
                                                                onKeyDown={(e) => e.preventDefault()}
                                                                className='form-control responsive-add-facility-input-fields' />
                                                        </div>
                                                        <div >
                                                            <label className='add-facility-modal-label'>Date of Purchase { <span style={{ color: "red" }}>*</span>}</label>
                                                            <input 
                                                            // min={contractStartDate} 
                                                            value={purchaseDate}
                                                            onChange={handlePurchaseDateChange}
                                                            onKeyDown={(e) => e.preventDefault()}
                                                            // disabled={viewClicked || contractStartDate === ''} 
                                                            type='date'
                                                            className='form-control responsive-add-facility-input-fields' />
                                                        </div>
                                                        <div>
                                                            <label className='add-facility-modal-label'>Brand<span style={{ color: "red" }}>*</span></label>
                                                            <input 
                                                            type='text' 
                                                            value={brand}
                                                            onChange={handleBrandChange}
                                                                className='form-control responsive-add-facility-input-fields' />
                                                        </div>
                                                    </div>
                                                    <div className="responsiveInputSection">
                                                        <span>
                                                            Description
                                                        </span>
                                                        <textarea 
                                                        className='form-control' 
                                                        required style={{ border: "0.5px solid #9D9D9C", marginLeft:"20px" }} 
                                                        value={description} 
                                                        onChange={handleDescriptionChange}
                                                         />
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="2" title="Purchase Details" className="assetTabs" disabled>
                                    <div className="infoBody">
                                            <div>
                                                {/* <h7 style={{ fontWeight: "bold" }}>Location of the Asset</h7> */}
                                                <div className="infoBodyInputs">
                                                    <div className="responsiveInputSection">
                                                        <div >
                                                            <label className='add-facility-modal-label'>Purchase Order No. <span style={{ color: "red" }}>*</span></label>
                                                            <input type='text'
                                                                value={purchaseOrderNo}
                                                                onChange={handlePurchaseOrderChange}
                                                                className='form-control responsive-add-facility-input-fields' />
                                                        </div>
                                                        <div >
                                                            <label className='add-facility-modal-label'>Date of Purchase <span style={{ color: "red" }}>*</span></label>
                                                            <input type='date'
                                                                value={purchaseDate}
                                                                onChange={handlePurchaseDateChange}
                                                                className='form-control responsive-add-facility-input-fields' />
                                                        </div>
                                                        <div >
                                                            <label className='add-facility-modal-label'>Cost of asset <span style={{ color: "red" }}>*</span></label>
                                                            <input type='number'
                                                                value={assetCost}
                                                                onChange={handleAssetCostChange}
                                                                className='form-control responsive-add-facility-input-fields' />
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div>
                                                <h7 style={{ fontWeight: "bold" }}>Vendor / Supplier Information</h7>
                                                <div className="infoBodyInputs">
                                                    <div className="responsiveInputSection">
                                                        <div >
                                                            <label className='add-facility-modal-label' 
                                                            // disabled={viewClicked}
                                                            >Select a Vendor / Slupplier {<span style={{ color: "red" }}>*</span>}</label>
                                                            <select 
                                                            // disabled={viewClicked} 
                                                            value={selectVendorSupplier} 
                                                            onChange={handleVendorSupplierChnge} 
                                                            className='form-select responsive-add-facility-input-fields'>
                                                                <option value="" >Select</option>
                                                                <option value="vendor1">Vendor 1</option>
                                                                <option value="supplier1">Supplier 1</option>
                                                                <option value="vendor2">Vendor 2</option>
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <Button className="form-control" disabled={selectServiceProvider} style={{ backgroundColor: "#B2B2B2", height: "70%", border:"none" }}>
                                                                + Add New Vendor / Supplier
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <hr></hr>
                                            {selectVendorSupplier !== ""?(
                                                <>
                                                    <div>
                                                        <div className="infoBodyInputs">
                                                            <div className="responsiveInputSection">
                                                                <div>
                                                                    <label className='add-facility-modal-label'>Vendor Name { <span style={{ color: "red" }}>*</span>}</label>
                                                                    <input 
                                                                    disabled={true} 
                                                                    type='text'
                                                                    value={vendorName}
                                                                    onChange={handleVendorNameChange}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Vendor Contact Number { <span style={{ color: "red" }}>*</span>}</label>
                                                                    <input 
                                                                    disabled={true} 
                                                                    type='text' 
                                                                    value={vendorContactNumber}
                                                                    onChange={handleVendorContactNumberChange} 
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Vendor Email {<span style={{ color: "red" }}>*</span>}</label>
                                                                    <input 
                                                                    disabled={true} 
                                                                    type='text' 
                                                                    value={vendorEmail}
                                                                    onChange={handleVendorEmailChange}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <hr></hr>
                                                        <div className="responsiveInputSection">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                    <label className='add-facility-modal-label'>GST Number </label>
                                                                    <input 
                                                                    disabled={true} 
                                                                    type='text' 
                                                                    value={gstNumberVendor} 
                                                                    onChange={handleGstNumberVendorChange} 
                                                                    // onKeyDown={handleKeyPressForGst} 
                                                                    className='form-control ' 
                                                                    style={{ width: "90%", border: "0.5px solid #9D9D9C" }} />
                                                                    {/* {!isValidGstNumber && <span className="input-error-text">Invalid GST number</span>} */}
                                                                </div>
                                                        </div>
                                                            <div>
                                                                <label className='add-facility-modal-label'>CIN
                                                                    </label>
                                                                <input type='text' 
                                                                disabled={true} 
                                                                value={cinNumberVendor}  
                                                                onChange={handleCinNumberVendorChange} 
                                                                className='form-control responsive-add-facility-input-fields' />
                                                                {/* {!isCinValid && <span className="input-error-text">Invalid CIN</span>} */}
                                                            </div>
                                                        </div>
                                                    <div>
                                                        <div className="infoBodyInputs">
                                                            <div className="responsiveInputSection">
                                                                <div>
                                                                    <label className='add-facility-modal-label'>Company Name </label>
                                                                    <input type='text' disabled={true} 
                                                                    value={companyNameVendor}
                                                                    onChange={handleCompanyNameVendorChange}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>PAN </label>
                                                                    <input type='text' 
                                                                    disabled={true} 
                                                                    value={panNameVendor}
                                                                    onChange={handlePanNameVendorChange} 
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div>
                                                                    <label className='add-facility-modal-label'>Street Address </label>
                                                                    <input type='text' 
                                                                    disabled={true} 
                                                                    value={registeredStreetAddressVendor}
                                                                    onChange={handleRegisteredStreetAddressVendorChange}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                            </div>
                                                            <div className="responsiveInputSection">

                                                                <div >
                                                                    <label className='add-facility-modal-label'>Street Address Lane 2 </label>
                                                                    <input type='text' 
                                                                    disabled={true} 
                                                                    value={registeredStreetAddressLane2Vendor}
                                                                    onChange={handleRegisteredStreetAddressLane2VendorChange}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>City </label>
                                                                    <input type='text' disabled={true} 
                                                                    value={registeredCityVendor}
                                                                    onChange={handleRegisteredCityVendorChange}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>State  </label>
                                                                    <input type='text' disabled={true} 
                                                                    value={registeredStateProvinceVendor}
                                                                    onChange={handleRegisteredStateProvinceVendorChange} 
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                            </div>
                                                            <div className="responsiveInputSection">

                                                                <div >
                                                                    <label className='add-facility-modal-label'>Pincode </label>
                                                                    <input type='text' disabled={true} 
                                                                    value={registeredPostalCodeVendor}
                                                                    onChange={handleRegisteredPostalCodeVendorChange}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr></hr>
                                                    <div>
                                                        <div style={{ display: "flex", justifyContent: "space-between", }}>
                                                            <h7 style={{ fontWeight: "bold" }}>Billing Address</h7>
                                                        </div>
                                                        <div className="infoBodyInputs">
                                                            <div className="responsiveInputSection">
                                                                <div>
                                                                    <label className='add-facility-modal-label'>Street Address </label>
                                                                    <input disabled={true} type='text' 
                                                                    value={billingStreetAddressVendor}
                                                                    onChange={handleBillingStreetAddressVendorChange}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Street Address Lane 2 </label>
                                                                    <input disabled={true} type='text' 
                                                                    value={billingStreetAddressLane2Vendor}
                                                                    onChange={handleBillingStreetAddressLane2VendorChange}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>City </label>
                                                                    <input disabled={true} type='text' 
                                                                    value={billingCityVendor}
                                                                    onChange={handleBillingCityVendorChange}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                            </div>
                                                            <div className="responsiveInputSection">
                                                                <div >
                                                                    <label className='add-facility-modal-label'>State </label>
                                                                    <input disabled={true} type='text' 
                                                                    value={billingStateProvinceVendor}
                                                                    onChange={handleBillingStateProvinceVendorChange} 
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Pincode </label>
                                                                    <input disabled={true} type='text' 
                                                                    // value={billingPostalCode} 
                                                                    // onChange={(e) => {
                                                                    //     const input = e.target.value
                                                                    //     const formattedInput = input.replace(/[^0-9]/g, '')
                                                                    //     const trimmedInput = formattedInput.trim();
                                                                    //     if (trimmedInput === '') {
                                                                    //         setIsPincodeValid(true);
                                                                    //     } else if (trimmedInput.length !== 6) {
                                                                    //         setIsPincodeValid(false);
                                                                    //     } else {
                                                                    //         setIsPincodeValid(true);
                                                                    //     }
                                                                    //     setBillingPostalCode(trimmedInput)
                                                                    // }} 
                                                                    value={billingPostalCodeVendor}
                                                                    onChange={handleBillingPostalCodeVendorChange}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                    {/* {!isPincodeValid && <span className="input-error-text">Invalid pincode</span>} */}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr></hr>
                                                    <div>
                                                        <h7 style={{ fontWeight: "bold" }}>Shipping Address</h7>
                                                        <div className="infoBodyInputs">
                                                            <div className="responsiveInputSection">
                                                                <div>
                                                                    <label className='add-facility-modal-label'>Street Address { <span style={{ color: "red" }}>*</span>}</label>
                                                                    <input 
                                                                    disabled={true} 
                                                                    type='text' 
                                                                    // value={shippingStreetAddress} 
                                                                    // onChange={(e) => setShippingStreetAddress(e.target.value)} 
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Street Address Lane 2 { <span style={{ color: "red" }}>*</span>}</label>
                                                                    <input 
                                                                    disabled={true} 
                                                                    type='text' 
                                                                    // value={shippingStreetAddressLane2} 
                                                                    // onChange={(e) => setShippingStreetAddressLane2(e.target.value)} 
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>City {<span style={{ color: "red" }}>*</span>}</label>
                                                                    <input 
                                                                    disabled={true} 
                                                                    type='text' 
                                                                    // value={shippingCity} 
                                                                    // onChange={(e) => setShippingCity(e.target.value)} 
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                            </div>
                                                            <div className="responsiveInputSection">
                                                                <div >
                                                                    <label className='add-facility-modal-label'>State { <span style={{ color: "red" }}>*</span>}</label>
                                                                    <input  type='text' 
                                                                    disabled={true} 
                                                                    // value={shippingStateProvince} 
                                                                    // onChange={(e) => setShippingStateProvince(e.target.value)} 
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Pincode { <span style={{ color: "red" }}>*</span>}</label>
                                                                    <input 
                                                                    disabled={true} 
                                                                    type='text' 
                                                                    // value={shippingPostalCode} 
                                                                    // onChange={(e) => {
                                                                    //     const input = e.target.value
                                                                    //     const formattedInput = input.replace(/[^0-9]/g, '')
                                                                    //     const trimmedInput = formattedInput.trim();
                                                                    //     if (trimmedInput === '') {
                                                                    //         setIsShippingPincodeValid(true);
                                                                    //     } else if (trimmedInput.length !== 6) {
                                                                    //         setIsShippingPincodeValid(false);
                                                                    //     } else {
                                                                    //         setIsShippingPincodeValid(true);
                                                                    //     }
                                                                    //     setShippingPostalCode(trimmedInput)
                                                                    // }} 
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                    {/* {!isShippingPincodeValid && <span className="input-error-text">Invalid pincode</span>} */}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr></hr>
                                                    <div style={{ display: "flex", justifyContent: "space-between", }}>
                                            <h7 style={{ fontWeight: "bold" }}>Contact Information</h7>
                                            {!viewClicked ?
                                                <span  style={{
                                                    textDecoration: "underline",
                                                    cursor: "pointer",
                                                    opacity: (listOfContactsValidation[listOfContactsValidation.length - 1]?.isValidEmail === true &&
                                                        listOfContacts[listOfContacts.length - 1].email !== '') ? '100%' : '50%'
                                                }}
                                                    className="C-pointer"
                                                    onClick={() => {
                                                        if (
                                                            listOfContactsValidation[listOfContactsValidation.length - 1]?.isValidEmail === true &&
                                                            listOfContacts[listOfContacts.length - 1].email !== ''
                                                        ) {
                                                            handleAddAnotherContact()
                                                            handleAddAnotherContactValidation()
                                                        }

                                                    }} > {listOfContactsValidation[listOfContactsValidation.length - 1]?.isValidEmail === true &&
                                                        listOfContacts[listOfContacts.length - 1].email !== ''}Add another contact</span>
                                                :
                                                null}
                                                    </div>

                                                    {listOfContacts && listOfContacts?.map((contact, index) => (
                                                        <div key={index} className="infoBodyInputs">
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px" }}>
                                                                <p style={{ fontWeight: "bold" }}>Contact {index + 1}</p>
                                                                {index !== 0 && !viewClicked ? (
                                                                    <div>
                                                                        <img src="./images/icons/crossWithCircle.svg" className="C-pointer" alt="cross"
                                                                            onClick={() => deleteSpecificContact(index, contact)} />
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                            <div className="responsiveInputSection" style={{ marginTop: "0px" }}>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Contact Name { <span style={{ color: "red" }}>*</span>}</label>
                                                                    <input type='text' disabled={viewClicked} className='form-control responsive-add-facility-input-fields' value={contact.name} 
                                                                    onChange={(e) => {
                                                                        const updatedContacts = [...listOfContacts];
                                                                        const updatedContactsValidation = [...listOfContactsValidation];
                                                                        const rawInput = e.target.value;
                                                                        const formattedInput = rawInput.replace(/[^A-Za-z\s]/g, '');
                                                                        const processedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                                        updatedContacts[index].name = processedInput;
                                                                        if (processedInput.length === 0 || processedInput.length < 3 || processedInput.length > 200) {
                                                                            updatedContactsValidation[index].isContactNameValid = false;
                                                                        } else {
                                                                            updatedContactsValidation[index].isContactNameValid = true;
                                                                        }
                                                                        setListOfContacts(updatedContacts);
                                                                        setListOfContactsValidation(updatedContactsValidation);
                                                                    }} />
                                                                    {!listOfContactsValidation[index]?.isContactNameValid && <span className="input-error-text">Must be min of 3 to max of 200 characters</span>}
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Designation </label>
                                                                    <input disabled={viewClicked} type='text' className='form-control responsive-add-facility-input-fields' value={contact.designation}
                                                                        onChange={(e) => {
                                                                            const updatedContacts = [...listOfContacts];
                                                                            const updatedContactsValidation = [...listOfContactsValidation];  
                                                                            const input = e.target.value;
                                                                            const trimmedInput = eleminateSpacesAtStartOfInputField(input);
                                                                            if (trimmedInput.length !== 0 && (trimmedInput.length > 200)) {
                                                                                updatedContactsValidation[index].isDesignationValid = false;
                                                                            } else {
                                                                                updatedContactsValidation[index].isDesignationValid = true;
                                                                            }
                                                                    
                                                                            const formattedInput = trimmedInput.replace(/[^a-zA-Z ]+/g, '');
                                                                            updatedContacts[index].designation = formattedInput;
                                                                    
                                                                            setListOfContacts(updatedContacts);
                                                                            setListOfContactsValidation(updatedContactsValidation);
                                                                        }} />
                                                                    {!listOfContactsValidation[index]?.isDesignationValid && <span className="input-error-text">Must be max of 200 characters</span>}
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Phone Number</label>
                                                                    <input type='text' disabled={viewClicked} className='form-control responsive-add-facility-input-fields' value={contact.phone_number}
                                                                        onChange={(e) => {
                                                                            const updatedContactsValidation = [...listOfContactsValidation]
                                                                            const input = e.target.value;
                                                                            const updatedContacts = [...listOfContacts];

                                                                            const formattedInput = input.replace(/\D/g, '');
                                                                            const trimmedInput = formattedInput.slice(0, 15);
                                                                            updatedContactsValidation[index].isValidPhone = trimmedInput.length === 0 || (trimmedInput.length >= 10 && trimmedInput.length <= 15);
                                                                            updatedContactsValidation[index].errorMsg = trimmedInput.length < 10 && trimmedInput.length > 0 ? 'Invalid phone number' : '';

                                                                            updatedContacts[index].phone_number = trimmedInput
                                                                            setListOfContactsValidation(updatedContactsValidation)
                                                                            setListOfContacts(updatedContacts);
                                                                        }} />
                                                                    {!listOfContactsValidation[index]?.isValidPhone && <span className="input-error-text">Invalid Phone Number</span>}
                                                                </div>
                                                            </div>
                                                            <div className="responsiveInputSection">
                                                                
                                                                
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Email <span style={{ color: "red" }}>*</span></label>
                                                                    <input type='text' disabled={viewClicked} className='form-control responsive-add-facility-input-fields'
                                                                    value={contact.email}

                                                                        onChange={(e) => handleEmailUpdateChange(e, index)}
                                                                        onBlur={onEmailInputBlur}
                                                                        />
                                                                    {!listOfContactsValidation[index]?.isValidEmail && <span className="input-error-text">Invalid email address</span>}
                                                                    {/* un comment the bellow line for duplicate email validation, tick and disable functionality should be added */}
                                                                    {/* {!listOfContactsValidation[index]?.isEmailNotDuplicate && <span className="input-error-text">Email already exists</span>} */}
                                                                </div>

                                                            </div>
                                                            
                                                        </div>
                                                    ))}
                                                
                                                    <hr></hr>
                                                </>
                                            )
                                            :
                                            null
                                            }


                                            <div>
                                                <UploadComponent
                                                    // onFilesSelected={handleFilesSelected}
                                                    uploadMethod="single"
                                                    // viewMode={!editFacilityDetails}
                                                    // uploadedFileData={documentsListArray}
                                                    // uploadActionClicked={handleDocumentUpload}
                                                    // deleteSpecificFileMain={confirmDeleteDocument}
                                                    maxNumberOfFiles={5}
                                                    // handleErrorFromDocument={handleErrorFromDocument}
                                                    // idType={"facility"}
                                                    acceptedFileTypes={".pdf, .jpeg, .jpg, .png"}
                                                />
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="3" title="AMC's" className="assetTabs" disabled>
                                        <div className="infoBody">
                                                {/* <h7 style={{ fontWeight: "bold" }}>Vendor / Supplier Information</h7> */}
                                                <div className="infoBodyInputs">
                                                    <div className="responsiveInputSection">
                                                        <div>
                                                            <label className='add-facility-modal-label'>AMC Start Date { <span style={{ color: "red" }}>*</span>}</label>
                                                            <input 
                                                            disabled={viewClicked} 
                                                            type='date' 
                                                            // value={shippingStreetAddress} 
                                                            // onChange={(e) => setShippingStreetAddress(e.target.value)} 
                                                            className='form-control responsive-add-facility-input-fields' />
                                                        </div>
                                                        <div >
                                                            <label className='add-facility-modal-label'>AMC End Date  { <span style={{ color: "red" }}>*</span>}</label>
                                                            <input 
                                                            // disabled={viewClicked} 
                                                            type='date' 
                                                            // value={shippingStreetAddressLane2} 
                                                            // onChange={(e) => setShippingStreetAddressLane2(e.target.value)} 
                                                            className='form-control responsive-add-facility-input-fields' />
                                                        </div>
                                                        <div >
                                                            <label className='add-facility-modal-label'>AMC to be Renewed By {<span style={{ color: "red" }}>*</span>}</label>
                                                            <input 
                                                            // disabled={viewClicked} 
                                                            type='date' 
                                                            // value={shippingCity} 
                                                            // onChange={(e) => setShippingCity(e.target.value)} 
                                                            className='form-control responsive-add-facility-input-fields' />
                                                        </div>
                                                    </div>
                                                    <div className="responsiveInputSection">
                                                        <div >
                                                            <label className='add-facility-modal-label' >AMC Renewal Period {<span style={{ color: "red" }}>*</span>}</label>
                                                            <select 
                                                             className='form-select responsive-add-facility-input-fields'>
                                                                <option value="inactive">Quaterly</option>
                                                                <option value="active">Half Yearly</option>
                                                                <option value="pending">Yearly</option>
                                                            </select>
                                                        </div>
                                                        <div >
                                                            <label className='add-facility-modal-label'>No of Services  { <span style={{ color: "red" }}>*</span>}</label>
                                                            <input 
                                                            // disabled={viewClicked} 
                                                            type='number' 
                                                            // value={shippingStreetAddressLane2} 
                                                            // onChange={(e) => setShippingStreetAddressLane2(e.target.value)} 
                                                            className='form-control responsive-add-facility-input-fields' />
                                                        </div>
                                                        <div >
                                                            <label className='add-facility-modal-label'>AMC Cost {<span style={{ color: "red" }}>*</span>}</label>
                                                            <input 
                                                            // disabled={viewClicked} 
                                                            type='number' 
                                                            // value={shippingCity} 
                                                            // onChange={(e) => setShippingCity(e.target.value)} 
                                                            className='form-control responsive-add-facility-input-fields' />
                                                        </div>
                                                    </div>
                                                    <div className="responsiveInputSection">
                                                        <div >
                                                            <label className='add-facility-modal-label' >Select Category{<span style={{ color: "red" }}>*</span>}</label>
                                                            <select 
                                                             className='form-select responsive-add-facility-input-fields'>
                                                                <option value="inactive">AMC</option>
                                                                <option value="active">AMC 1</option>
                                                                <option value="pending">AMC 2</option>
                                                            </select>
                                                        </div>
                                                        
                                                    </div>
                                                    <hr></hr>
                                                    <div>
                                                        <h7 style={{ fontWeight: "bold" }}>Service Provider Information </h7>
                                                        <div className="infoBodyInputs">
                                                            <div className="responsiveInputSection">
                                                                <div >
                                                                    <label className='add-facility-modal-label' 
                                                                    // disabled={viewClicked}
                                                                    >Select Service Provider {<span style={{ color: "red" }}>*</span>}</label>
                                                                    <select 
                                                                    disabled={viewClicked} 
                                                                    value={selectServiceProvider} 
                                                                    onChange={handleServiceProviderChange} 
                                                                    className='form-select responsive-add-facility-input-fields'>
                                                                        <option value="">Select</option>
                                                                        <option value="provider1">Provider 1</option>
                                                                        <option value="provider2">Provider 2</option>
                                                                        <option value="peovider3">Provider 3</option>
                                                                    </select>
                                                                </div>
                                                                <div>
                                                                    <Button className="form-control" disabled={selectServiceProvider !== ""} style={{ backgroundColor: "#B2B2B2", height: "70%", border:"none" }}>
                                                                        + Add New Service Provider
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr></hr>
                                                    {selectServiceProvider !== ""? (
                                                        <>
                                                            <div className="infoBodyInputs">
                                                                    <div className="responsiveInputSection">
                                                                        <div>
                                                                            <label className='add-facility-modal-label'>AMC Service Provider { <span style={{ color: "red" }}>*</span>}</label>
                                                                            <input 
                                                                            disabled={true} 
                                                                            type='text' 
                                                                            // value={shippingStreetAddress} 
                                                                            // onChange={(e) => setShippingStreetAddress(e.target.value)} 
                                                                            className='form-control responsive-add-facility-input-fields' />
                                                                        </div>
                                                                        <div >
                                                                            <label className='add-facility-modal-label'>Contact Number { <span style={{ color: "red" }}>*</span>}</label>
                                                                            <input 
                                                                            disabled={true} 
                                                                            type='text' 
                                                                            // value={shippingStreetAddressLane2} 
                                                                            // onChange={(e) => setShippingStreetAddressLane2(e.target.value)} 
                                                                            className='form-control responsive-add-facility-input-fields' />
                                                                        </div>
                                                                        <div >
                                                                            <label className='add-facility-modal-label'>Email {<span style={{ color: "red" }}>*</span>}</label>
                                                                            <input 
                                                                            disabled={true} 
                                                                            type='text' 
                                                                            // value={shippingCity} 
                                                                            // onChange={(e) => setShippingCity(e.target.value)} 
                                                                            className='form-control responsive-add-facility-input-fields' />
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                    
                                                            <hr></hr>
                                                            <div className="infoBodyInputs">
                                                                <div className="responsiveInputSection">
                                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                                            <label className='add-facility-modal-label'>GST Number </label>
                                                                            <input 
                                                                            disabled={true} 
                                                                            type='text' 
                                                                            // value={gstNumberServiceProvider} 
                                                                            // onChange={handleGstNumberChange} 
                                                                            // onKeyDown={handleKeyPressForGst} 
                                                                            className='form-control ' 
                                                                            style={{ width: "90%", border: "0.5px solid #9D9D9C" }} />
                                                                            {/* {!isValidGstNumber && <span className="input-error-text">Invalid GST number</span>} */}
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label className='add-facility-modal-label'>CIN
                                                                            {/* {<span style={{ color: "red" }}>*</span>} */}
                                                                            </label>
                                                                        <input type='text' 
                                                                        disabled={true} 
                                                                        // value={cinNumber}  
                                                                        // onChange={handleCinNumberChange} 
                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                        {/* {!isCinValid && <span className="input-error-text">Invalid CIN</span>} */}
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="infoBodyInputs">
                                                                        <div className="responsiveInputSection">
                                                                            <div>
                                                                                <label className='add-facility-modal-label'>Company Name </label>
                                                                                <input type='text' disabled={true} 
                                                                                // value={companyName} 
                                                                                // onChange={(e) => setCompanyName(e.target.value)} 
                                                                                className='form-control responsive-add-facility-input-fields' />
                                                                            </div>
                                                                            <div >
                                                                                <label className='add-facility-modal-label'>PAN </label>
                                                                                <input type='text' 
                                                                                disabled={true} 
                                                                                // value={panName} 
                                                                                // onChange={(e) => setPanName(e.target.value)} 
                                                                                className='form-control responsive-add-facility-input-fields' />
                                                                            </div>
                                                                            <div>
                                                                                <label className='add-facility-modal-label'>Street Address </label>
                                                                                <input type='text' 
                                                                                disabled={true} 
                                                                                // value={registeredStreetAddress}
                                                                                //  onChange={(e) => setRegisteredStreetAddress(e.target.value)}
                                                                                className='form-control responsive-add-facility-input-fields' />
                                                                            </div>
                                                                        </div>
                                                                        <div className="responsiveInputSection">

                                                                            <div >
                                                                                <label className='add-facility-modal-label'>Street Address Lane 2 </label>
                                                                                <input type='text' 
                                                                                disabled={true} 
                                                                                // value={registeredStreetAddressLane2} 
                                                                                // onChange={(e) => setRegisteredStreetAddressLane2(e.target.value)} 
                                                                                className='form-control responsive-add-facility-input-fields' />
                                                                            </div>
                                                                            <div >
                                                                                <label className='add-facility-modal-label'>City </label>
                                                                                <input type='text' disabled={true} 
                                                                                // value={registeredCity} 
                                                                                // onChange={(e) => setRegisteredCity(e.target.value)}
                                                                                className='form-control responsive-add-facility-input-fields' />
                                                                            </div>
                                                                            <div >
                                                                                <label className='add-facility-modal-label'>State  </label>
                                                                                <input type='text' disabled={true} 
                                                                                // value={registeredStateProvince} 
                                                                                // onChange={(e) => setRegisteredStateProvince(e.target.value)} 
                                                                                className='form-control responsive-add-facility-input-fields' />
                                                                            </div>
                                                                        </div>
                                                                        <div className="responsiveInputSection">

                                                                            <div >
                                                                                <label className='add-facility-modal-label'>Pincode </label>
                                                                                <input type='text' disabled={true} 
                                                                                // value={registeredPostalCode} 
                                                                                // onChange={(e) => setRegisteredPostalCode(e.target.value)} 
                                                                                className='form-control responsive-add-facility-input-fields' />
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                                <hr></hr>
                                                                <div>
                                                                    <div style={{ display: "flex", justifyContent: "space-between", }}>
                                                                        <h7 style={{ fontWeight: "bold" }}>Billing Address</h7>
                                                                        
                                                                    </div>
                                                                    <div className="infoBodyInputs">
                                                                        <div className="responsiveInputSection">
                                                                            <div>
                                                                                <label className='add-facility-modal-label'>Street Address </label>
                                                                                <input disabled={true} type='text' 
                                                                                // value={billingStreetAddress} 
                                                                                // onChange={(e) => setBillingStreetAddress(e.target.value)} 
                                                                                className='form-control responsive-add-facility-input-fields' />
                                                                            </div>
                                                                            <div >
                                                                                <label className='add-facility-modal-label'>Street Address Lane 2 </label>
                                                                                <input disabled={true} type='text' 
                                                                                // value={billingStreetAddressLane2} 
                                                                                // onChange={(e) => setBillingStreetAddressLane2(e.target.value)} 
                                                                                className='form-control responsive-add-facility-input-fields' />
                                                                            </div>
                                                                            <div >
                                                                                <label className='add-facility-modal-label'>City </label>
                                                                                <input disabled={true} type='text' 
                                                                                // value={billingCity} 
                                                                                // onChange={(e) => setBillingCity(e.target.value)} 
                                                                                className='form-control responsive-add-facility-input-fields' />
                                                                            </div>
                                                                        </div>
                                                                        <div className="responsiveInputSection">
                                                                            <div >
                                                                                <label className='add-facility-modal-label'>State </label>
                                                                                <input disabled={true} type='text' 
                                                                                // value={billingStateProvince} 
                                                                                // onChange={(e) => setBillingStateProvince(e.target.value)} 
                                                                                className='form-control responsive-add-facility-input-fields' />
                                                                            </div>
                                                                            <div >
                                                                                <label className='add-facility-modal-label'>Pincode </label>
                                                                                <input disabled={true} type='text' 
                                                                                // value={billingPostalCode}
                                                                                className='form-control responsive-add-facility-input-fields' />
                                                                                {/* {!isPincodeValid && <span className="input-error-text">Invalid pincode</span>} */}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr></hr>
                                                                <div>
                                                                    <h7 style={{ fontWeight: "bold" }}>Shipping Address</h7>
                                                                    <div className="infoBodyInputs">
                                                                        <div className="responsiveInputSection">
                                                                            <div>
                                                                                <label className='add-facility-modal-label'>Street Address { <span style={{ color: "red" }}>*</span>}</label>
                                                                                <input 
                                                                                disabled={true} 
                                                                                type='text' 
                                                                                // value={shippingStreetAddress} 
                                                                                // onChange={(e) => setShippingStreetAddress(e.target.value)} 
                                                                                className='form-control responsive-add-facility-input-fields' />
                                                                            </div>
                                                                            <div >
                                                                                <label className='add-facility-modal-label'>Street Address Lane 2 { <span style={{ color: "red" }}>*</span>}</label>
                                                                                <input 
                                                                                disabled={true} 
                                                                                type='text' 
                                                                                // value={shippingStreetAddressLane2} 
                                                                                // onChange={(e) => setShippingStreetAddressLane2(e.target.value)} 
                                                                                className='form-control responsive-add-facility-input-fields' />
                                                                            </div>
                                                                            <div >
                                                                                <label className='add-facility-modal-label'>City {<span style={{ color: "red" }}>*</span>}</label>
                                                                                <input 
                                                                                disabled={true} 
                                                                                type='text' 
                                                                                // value={shippingCity} 
                                                                                // onChange={(e) => setShippingCity(e.target.value)} 
                                                                                className='form-control responsive-add-facility-input-fields' />
                                                                            </div>
                                                                        </div>
                                                                        <div className="responsiveInputSection">
                                                                            <div >
                                                                                <label className='add-facility-modal-label'>State { <span style={{ color: "red" }}>*</span>}</label>
                                                                                <input  type='text' 
                                                                                disabled={true}
                                                                                // value={shippingStateProvince} 
                                                                                // onChange={(e) => setShippingStateProvince(e.target.value)} 
                                                                                className='form-control responsive-add-facility-input-fields' />
                                                                            </div>
                                                                            <div >
                                                                                <label className='add-facility-modal-label'>Pincode { <span style={{ color: "red" }}>*</span>}</label>
                                                                                <input 
                                                                                disabled={true} 
                                                                                type='text' 
                                                                                // value={shippingPostalCode} 
                                                                                // onChange={(e) => {
                                                                                //     const input = e.target.value
                                                                                //     const formattedInput = input.replace(/[^0-9]/g, '')
                                                                                //     const trimmedInput = formattedInput.trim();
                                                                                //     if (trimmedInput === '') {
                                                                                //         setIsShippingPincodeValid(true);
                                                                                //     } else if (trimmedInput.length !== 6) {
                                                                                //         setIsShippingPincodeValid(false);
                                                                                //     } else {
                                                                                //         setIsShippingPincodeValid(true);
                                                                                //     }
                                                                                //     setShippingPostalCode(trimmedInput)
                                                                                // }} 
                                                                                className='form-control responsive-add-facility-input-fields' />
                                                                                {/* {!isShippingPincodeValid && <span className="input-error-text">Invalid pincode</span>} */}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr></hr>
                                                                <div style={{ display: "flex", justifyContent: "space-between", }}>
                                                                <h7 style={{ fontWeight: "bold" }}>Contact Information</h7>
                                                                {!viewClicked ?
                                                                    <span  style={{
                                                                        textDecoration: "underline",
                                                                        cursor: "pointer",
                                                                        opacity: (listOfContactsValidation[listOfContactsValidation.length - 1]?.isValidEmail === true &&
                                                                            listOfContacts[listOfContacts.length - 1].email !== '') ? '100%' : '50%'
                                                                    }}
                                                                        className="C-pointer"
                                                                        onClick={() => {
                                                                            if (
                                                                                listOfContactsValidation[listOfContactsValidation.length - 1]?.isValidEmail === true &&
                                                                                listOfContacts[listOfContacts.length - 1].email !== ''
                                                                            ) {
                                                                                handleAddAnotherContact()
                                                                                handleAddAnotherContactValidation()
                                                                            }

                                                                        }} > {listOfContactsValidation[listOfContactsValidation.length - 1]?.isValidEmail === true &&
                                                                            listOfContacts[listOfContacts.length - 1].email !== ''}Add another contact</span>
                                                                    :
                                                                    null}
                                                                </div>
                                                            

                                                            {listOfContacts && listOfContacts?.map((contact, index) => (
                                                                <div key={index} className="infoBodyInputs">
                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px" }}>
                                                                        <p style={{ fontWeight: "bold" }}>Contact {index + 1}</p>
                                                                        {index !== 0 && !viewClicked ? (
                                                                            <div>
                                                                                <img src="./images/icons/crossWithCircle.svg" className="C-pointer" alt="cross"
                                                                                    onClick={() => deleteSpecificContact(index, contact)} />
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="responsiveInputSection" style={{ marginTop: "0px" }}>
                                                                        <div >
                                                                            <label className='add-facility-modal-label'>Contact Name { <span style={{ color: "red" }}>*</span>}</label>
                                                                            <input type='text' disabled={viewClicked} className='form-control responsive-add-facility-input-fields' value={contact.name} 
                                                                            onChange={(e) => {
                                                                                const updatedContacts = [...listOfContacts];
                                                                                const updatedContactsValidation = [...listOfContactsValidation];
                                                                                const rawInput = e.target.value;
                                                                                const formattedInput = rawInput.replace(/[^A-Za-z\s]/g, '');
                                                                                const processedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                                                updatedContacts[index].name = processedInput;
                                                                                if (processedInput.length === 0 || processedInput.length < 3 || processedInput.length > 200) {
                                                                                    updatedContactsValidation[index].isContactNameValid = false;
                                                                                } else {
                                                                                    updatedContactsValidation[index].isContactNameValid = true;
                                                                                }
                                                                                setListOfContacts(updatedContacts);
                                                                                setListOfContactsValidation(updatedContactsValidation);
                                                                            }} />
                                                                            {!listOfContactsValidation[index]?.isContactNameValid && <span className="input-error-text">Must be min of 3 to max of 200 characters</span>}
                                                                        </div>
                                                                        <div >
                                                                            <label className='add-facility-modal-label'>Designation </label>
                                                                            <input disabled={viewClicked} type='text' className='form-control responsive-add-facility-input-fields' value={contact.designation}
                                                                                onChange={(e) => {
                                                                                    const updatedContacts = [...listOfContacts];
                                                                                    const updatedContactsValidation = [...listOfContactsValidation];  
                                                                                    const input = e.target.value;
                                                                                    const trimmedInput = eleminateSpacesAtStartOfInputField(input);
                                                                                    if (trimmedInput.length !== 0 && (trimmedInput.length > 200)) {
                                                                                        updatedContactsValidation[index].isDesignationValid = false;
                                                                                    } else {
                                                                                        updatedContactsValidation[index].isDesignationValid = true;
                                                                                    }
                                                                            
                                                                                    const formattedInput = trimmedInput.replace(/[^a-zA-Z ]+/g, '');
                                                                                    updatedContacts[index].designation = formattedInput;
                                                                            
                                                                                    setListOfContacts(updatedContacts);
                                                                                    setListOfContactsValidation(updatedContactsValidation);
                                                                                }} />
                                                                            {!listOfContactsValidation[index]?.isDesignationValid && <span className="input-error-text">Must be max of 200 characters</span>}
                                                                        </div>
                                                                        <div >
                                                                            <label className='add-facility-modal-label'>Phone Number</label>
                                                                            <input type='text' disabled={viewClicked} className='form-control responsive-add-facility-input-fields' value={contact.phone_number}
                                                                                onChange={(e) => {
                                                                                    const updatedContactsValidation = [...listOfContactsValidation]
                                                                                    const input = e.target.value;
                                                                                    const updatedContacts = [...listOfContacts];

                                                                                    const formattedInput = input.replace(/\D/g, '');
                                                                                    const trimmedInput = formattedInput.slice(0, 15);
                                                                                    updatedContactsValidation[index].isValidPhone = trimmedInput.length === 0 || (trimmedInput.length >= 10 && trimmedInput.length <= 15);
                                                                                    updatedContactsValidation[index].errorMsg = trimmedInput.length < 10 && trimmedInput.length > 0 ? 'Invalid phone number' : '';

                                                                                    updatedContacts[index].phone_number = trimmedInput
                                                                                    setListOfContactsValidation(updatedContactsValidation)
                                                                                    setListOfContacts(updatedContacts);
                                                                                }} />
                                                                            {!listOfContactsValidation[index]?.isValidPhone && <span className="input-error-text">Invalid Phone Number</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="responsiveInputSection">
                                                                        
                                                                        
                                                                        <div >
                                                                            <label className='add-facility-modal-label'>Email <span style={{ color: "red" }}>*</span></label>
                                                                            <input type='text' disabled={viewClicked} className='form-control responsive-add-facility-input-fields'
                                                                            value={contact.email}

                                                                                // onChange={(e) => handleEmailUpdateChange(e, index)}
                                                                                // onBlur={onEmailInputBlur}
                                                                                />
                                                                            {!listOfContactsValidation[index]?.isValidEmail && <span className="input-error-text">Invalid email address</span>}
                                                                            {/* un comment the bellow line for duplicate email validation, tick and disable functionality should be added */}
                                                                            {/* {!listOfContactsValidation[index]?.isEmailNotDuplicate && <span className="input-error-text">Email already exists</span>} */}
                                                                        </div>
                                                                    

                                                                    </div>
                                                                    
                                                                </div>
                                                            ))}
                                                            <hr></hr>
                                                            
                                                        </>

                                                    ) 
                                                    :
                                                    null
                                                    }
                                                    
                                                    <div>
                                                        <UploadComponent
                                                            // onFilesSelected={handleFilesSelected}
                                                            uploadMethod="single"
                                                            // viewMode={!editFacilityDetails}
                                                            // uploadedFileData={documentsListArray}
                                                            // uploadActionClicked={handleDocumentUpload}
                                                            // deleteSpecificFileMain={confirmDeleteDocument}
                                                            maxNumberOfFiles={5}
                                                            // handleErrorFromDocument={handleErrorFromDocument}
                                                            // idType={"facility"}
                                                            acceptedFileTypes={".pdf, .jpeg, .jpg, .png"}
                                                        />
                                                    </div>
                                                    <hr></hr>
                                                    {
                                                        viewClicked ? (

                                                            <div>
                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                                            <div style={{display:"flex", alignItems:"center"}}>
                                                                <h5 style={{ fontWeight: "bold" }}>AMC's History</h5>

                                                            </div>
                                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-around"}}>
                                                                    <div >
                                                                        <label className='add-facility-modal-label'>Start Date</label>
                                                                        <input 
                                                                        // disabled={viewClicked} 
                                                                        type='date' 
                                                                        // value={shippingCity} 
                                                                        // onChange={(e) => setShippingCity(e.target.value)} 
                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                    </div>
                                                                    <div >
                                                                        <label className='add-facility-modal-label'>End Date</label>
                                                                        <input 
                                                                        // disabled={viewClicked} 
                                                                        type='date' 
                                                                        // value={shippingCity} 
                                                                        // onChange={(e) => setShippingCity(e.target.value)} 
                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                    </div>
                                                                

                                                            </div>
                                                            <Pagination
                                                                handledPaginationNextClicked={handledPaginationNextClicked}
                                                                handledPaginationPrevClicked={handledPaginationPrevClicked}
                                                                isApplyClicked
                                                                totalRecords={200}
                                                                recordsPerPage={10}
                                                                currentPaginationPage={currentPaginationPage}
                                                            />
                                                        </div>
                                                        <div className="infoBodyInputs">
                                                            <TableComponent
                                                                headers = {[{
                                                                    name: "AMC Service Start Date",
                                                                    type: "date",
                                                                    key: "installation_date",
                                                                    sort: true
                                                                }, {
                                                                    name: "AMC Service Start Date",
                                                                    type: "date",
                                                                    key: "installation_date",
                                                                    sort: true
                                                                }, {
                                                                    name: "Service Performed",
                                                                    type: "text",
                                                                    key: "asset_id",
                                                                    sort: true
                                                                }, {
                                                                    name: "Service Date",
                                                                    type: "date",
                                                                    key: "installation_date",
                                                                    sort: true
                                                                }, {
                                                                    name: "Service Report Number",
                                                                    type: "text",
                                                                    key: "facility_name",
                                                                    sort: true
                                                                }, {
                                                                    name: "Issues (If Any)",
                                                                    type: "text",
                                                                    key: "facility_name",
                                                                    sort: true
                                                                }
                                                                ] }

                                                                data={assetListDummyData}
                                                                />

                                                        </div>
                                                            </div>

                                                        ) : null
                                                    }
                                                
                                            </div>
                                            </div>
                                    </Tab>
                                    <Tab eventKey="4" title="SOP & Forms" className="assetTabs" disabled>
                                    <div className="infoBody">
                                        <div style={{display:"flex", flexDirection:"row", width:"100%", margin:"20px", alignItems:"end"}}>
                                            <div style={{width:"80%"}}>
                                                <label className='add-facility-modal-label'>Select SOP for Asset</label>
                                                <select 
                                                className="form-select" style={{width:"100%"}}
                                                >
                                            
                                                        <option value="inactive">SOP 1</option>
                                                        <option value="active">SOP 2</option>
                                                        <option value="pending">SOP 3</option>
                                                    </select>

                                            </div>
                                            <div style={{textDecoration:"underLine", cursor:"pointer" , marginBottom:"10px", marginLeft:"40px"}}>
                                                View SOP
                                            </div>

                                        </div>
                                        <div style={{display:"flex", flexDirection:"row", width:"100%", margin:"20px", alignItems:"end"}}>
                                            <div style={{width:"80%"}}>
                                                <label className='add-facility-modal-label'>Select Checklist for Asset</label>
                                                <select
                                                className="form-select" style={{width:"100%"}}
                                                >
                                                    <option value="inactive">Checklist 1</option>
                                                    <option value="active">Checklist 2</option>
                                                    <option value="pending">Checklist 3</option>
                                                </select>

                                            </div>
                                            <div style={{textDecoration:"underLine", cursor:"pointer" , marginBottom:"10px", marginLeft:"40px"}}>
                                                View Checklist
                                            </div>

                                        </div>
                                        

                                        <hr></hr>
                                        {
                                            viewClicked ? (
                                                <div>
                                                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                                        <div style={{display:"flex", alignItems:"center"}}>
                                                            <h5 style={{ fontWeight: "bold" }}>Checklist History</h5>

                                                        </div>
                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-around"}}>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Start Date</label>
                                                                    <input 
                                                                    // disabled={viewClicked} 
                                                                    type='date' 
                                                                    // value={shippingCity} 
                                                                    // onChange={(e) => setShippingCity(e.target.value)} 
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>End Date</label>
                                                                    <input 
                                                                    // disabled={viewClicked} 
                                                                    type='date' 
                                                                    // value={shippingCity} 
                                                                    // onChange={(e) => setShippingCity(e.target.value)} 
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                            

                                                        </div>
                                                        <div style={{display:"flex", alignItems:"start"}}>
                                                            <Pagination
                                                                handledPaginationNextClicked={handledPaginationNextClicked}
                                                                handledPaginationPrevClicked={handledPaginationPrevClicked}
                                                                isApplyClicked
                                                                totalRecords={200}
                                                                recordsPerPage={10}
                                                                currentPaginationPage={currentPaginationPage}
                                                            />
                                                        </div>
                                                        
                                                </div>
                                                
                                                <div className="infoBodyInputs">
                                                    <TableComponent
                                                        headers = {[{
                                                            name: "AMC Service Start Date",
                                                            type: "date",
                                                            key: "installation_date",
                                                            sort: true
                                                        }, {
                                                            name: "AMC Service Start Date",
                                                            type: "date",
                                                            key: "installation_date",
                                                            sort: true
                                                        }, {
                                                            name: "Service Performed",
                                                            type: "text",
                                                            key: "asset_id",
                                                            sort: true
                                                        }, {
                                                            name: "Service Date",
                                                            type: "date",
                                                            key: "installation_date",
                                                            sort: true
                                                        }, {
                                                            name: "Service Report Number",
                                                            type: "text",
                                                            key: "facility_name",
                                                            sort: true
                                                        }, {
                                                            name: "Issues (If Any)",
                                                            type: "text",
                                                            key: "facility_name",
                                                            sort: true
                                                        }
                                                        ] }

                                                        data={assetListDummyData}
                                                        />

                                                </div>
                                                </div>

                                            ): null
                                        }
                                            
                                    </div>
                                    </Tab>
                                </Tabs>
                            </div>
                            <div style={{marginTop:"20px", marginBottom:"100px"}}>
                                <div style={{display:"flex", justifyContent:"end", }}>
                                    <button className="invoice_btn" style={{color:"white"}} onClick={handleBack}>Back</button>
                                    <button className="invoice_btn" style={{marginLeft:"30px", color:"white"}} onClick={handleNext}>Save and Next</button>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                ) : null
            }
            <Footer/>
    </>
  )
}
