import React, { useEffect, useState } from 'react'
import WorkLog from '../Reusable_Components/Work_Log/WorkLog.jsx';
import { IoAddOutline } from 'react-icons/io5';
import NavBarTop from '../Reusable_Components/NavBarTop.jsx';
import LeftNavBar from '../Reusable_Components/LeftNavBar.jsx';
import Search from '../Reusable_Components/Search.jsx';
import Dropdown from '../Reusable_Components/Dropdown.jsx';

const facilitiesList = [
    {
        name: "ACL",
        id: 1,
        backgroundColor: "rgba(198, 237, 201)",
        color: "red"
    },
    {
        name: "ACLC",
        id: 2,
        backgroundColor: "rgba(226, 181, 227)",
        color: "green"
    },
    {
        name: "ACLC-2A",
        id: 3,
        backgroundColor: "rgba(96, 211, 230, 0.7)",
        color: "blue"
    },
    {
        name: "ACLC-2B",
        id: 4,
        backgroundColor: "rgba(228, 175, 118,0.7)",
        color: "yellow"
    },
]

let data = {
    "PPM": [
        {
            eventName: "Perform PPM on DG-1",
            scheduledDate: "20-09-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "10:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "todo",
            assignedTo: "Raj"
        },
        {
            eventName: "Perform PPM on DG-2",
            scheduledDate: "20-09-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "12:00 PM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "todo",
            assignedTo: "Rajesh"
        },
        {
            eventName: "Perform PPM on DG-3",
            scheduledDate: "20-09-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "02:00 PM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC",
            status: "todo",
            assignedTo: "Dinesh Karri"
        },
        {
            eventName: "Perform Fire Drill at ACLC-2A",
            scheduledDate: "20-09-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2A",
            status: "inprogress",
            assignedTo: "Dinesh Dunna"
        },
        {
            eventName: "Perform PPM on Dry transformer-1",
            scheduledDate: "20-09-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "inprogress",
            assignedTo: "Akhil Yerramilli"
        },
        {
            eventName: "Perform PPM on Dry transformer-5",
            scheduledDate: "20-09-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2B",
            status: "approval",
            assignedTo: "Bharat Ram"
        },
        {
            eventName: "Perform Meter Readings",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "completed",
            assignedTo: "Yashwanth palika"
        }
    ],
    "SOP": [
        {
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "10:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "todo",
            assignedTo: "Raj"
        },
        {
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "12:00 PM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC",
            status: "todo",
            assignedTo: "Rajesh"
        },
        {
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "02:00 PM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2A",
            status: "todo",
            assignedTo: "Dinesh Karri"
        },
        {
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2B",
            status: "inprogress",
            assignedTo: "Dinesh Dunna"
        },
        {
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "approval",
            assignedTo: "Akhil Yerramilli"
        },
        {
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2A",
            status: "approval",
            assignedTo: "Akhil Yerramilli"
        },
        {
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "approval",
            assignedTo: "Akhil Yerramilli"
        },
        {
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "approval",
            assignedTo: "Akhil Yerramilli"
        },
        {
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "completed",
            assignedTo: "Bharat Ram"
        },
        {
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "completed",
            assignedTo: "Yashwanth palika"
        }
    ]
}

const assignee_list = [
    {
        name: "Raj"
    },
    {
        name: "Rajesh"
    },
    {
        name: "Dinesh Karri"
    },
    {
        name: "Dinesh Dunna"
    },
    {
        name: "Akhil Yerramilli"
    },
    {
        name: "Bharat Ram"
    },
    {
        name: "Yashwanth palika"
    },
    {
        name: "Pavani Sreemukhi"
    },
    {
        name: "Yashwanth kumar"
    }
]

const colors = [
    "#15BDFA",
    "#369C2A",
    "#5E40A1",
    "#F7BC26",
    "#0F2D80",
    "#DF4451",
]

export default function FacilityManagerWorkLog() {

    const [columnNames, setColumnNames] = useState([])
    const [tasks, setTasks] = useState([])
    const [filteredTasks, setFilteredTasks] = useState([])
    const [assignee, setAssignee] = useState([])
    const [remainingAssignee, setRemainingAssignee] = useState([])
    const [selectedAssignee, setSelectedAssignee] = useState([])
    const [selectedRemainingAssignee, setSelectedRemainingAssignee] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [fromDate, setFromDate] = useState(() => {
        const date = new Date();
        date.setDate(date.getDate() - 7);
        return date.toISOString().split('T')[0];
    });
    const [toDate, setToDate] = useState(() => {
        const date = new Date();
        date.setDate(date.getDate() - 7);
        const fromDate = date.toISOString().split('T')[0];
        date.setMonth(date.getMonth() + 1);
        const toDate = date.toISOString().split('T')[0];
        return toDate;
    });
    const [selectedFacility, setSelectedFacility] = useState('all')
    const [sections, setSections] = useState([])

    useEffect(() => {
        const getUniqueStatuses = (data) => {
            const statuses = new Set();
            Object.values(data).forEach(tasks => {
                tasks.forEach(task => {
                    statuses.add(task.status);
                });
            });
            return Array.from(statuses);
        };
        setColumnNames(getUniqueStatuses(data));
    }, [fromDate, toDate]);

    useEffect(() => {
        setSections(Object.keys(data)?.map((each) => {
            return {
                tab: each,
                isOpen: false
            }
        }))
    }, [])



    useEffect(() => {
        const filteredTasks = Object.keys(data).reduce((acc, categoryKey) => {
            const categoryTasks = data[categoryKey];

            const filteredCategoryTasks = categoryTasks.filter(task => {
                const taskDate = new Date(task.scheduledDate.split('-').reverse().join('-'));
                const from = new Date(fromDate);
                const to = new Date(toDate);

                return taskDate >= from && taskDate <= to;
            });

            acc[categoryKey] = filteredCategoryTasks;
            return acc;
        }, {});

        setFilteredTasks(filteredTasks);
        setAssignee(assignee_list.slice(0, 5));
        setRemainingAssignee(assignee_list.slice(5));
    }, [fromDate, toDate]);

    const getInitials = (name) => {
        return name?.split(' ')
            .map(word => word[0].toUpperCase())
            .join('');
    };

    const randomColor = (name) => {
        const initials = getInitials(name);
        const charCode = initials.charCodeAt(0);
        const colorIndex = charCode % colors.length;
        return colors[colorIndex];
    }

    const filterAssignee = (assignee) => {
        let updatedSelectedAssignee;
        if (selectedAssignee.includes(assignee?.name)) {
            updatedSelectedAssignee = selectedAssignee.filter(selected => selected !== assignee?.name);
        } else {
            updatedSelectedAssignee = [...selectedAssignee, assignee?.name];
        }

        setSelectedAssignee(updatedSelectedAssignee);

        const assigneeFilter = updatedSelectedAssignee.length > 0 ? updatedSelectedAssignee : assignee_list.map(a => a.name);

        const filteredTasks = Object.keys(data).reduce((acc, categoryKey) => {
            const categoryTasks = data[categoryKey];

            const filteredCategoryTasks = categoryTasks.filter(task => {
                const taskDate = new Date(task.scheduledDate.split('-').reverse().join('-'));
                const from = new Date(fromDate);
                const to = new Date(toDate);

                return (
                    task.eventName.toLowerCase().includes(searchText.toLowerCase()) &&
                    assigneeFilter.includes(task.assignedTo) &&
                    taskDate >= from && taskDate <= to &&
                    (selectedFacility === 'all' || task.facility === selectedFacility)
                );
            });

            acc[categoryKey] = filteredCategoryTasks;
            return acc;
        }, {});

        setTasks(filteredTasks);
        setFilteredTasks(filteredTasks);
    };

    const handleSearchTasks = (e) => {
        const searchText = e.target.value;
        setSearchText(searchText);

        const assigneeFilter = selectedAssignee.length > 0 ? selectedAssignee : assignee_list.map(a => a.name);

        const filteredTasks = Object.keys(data).reduce((acc, categoryKey) => {
            const categoryTasks = data[categoryKey];

            const filteredCategoryTasks = categoryTasks.filter(task => {
                const taskDate = new Date(task.scheduledDate.split('-').reverse().join('-'));
                const from = new Date(fromDate);
                const to = new Date(toDate);

                return (
                    task.eventName.toLowerCase().includes(searchText.toLowerCase()) &&
                    assigneeFilter.includes(task.assignedTo) &&
                    taskDate >= from && taskDate <= to &&
                    (selectedFacility === 'all' || task.facility === selectedFacility)
                );
            });

            acc[categoryKey] = filteredCategoryTasks;
            return acc;
        }, {});

        setTasks(filteredTasks);
        setFilteredTasks(filteredTasks);
    };

    const handleChangeFromDate = (e) => {
        setFromDate(e.target.value);
        const from = new Date(e.target.value);
        const to = new Date(toDate);

        const assigneeFilter = selectedAssignee.length > 0 ? selectedAssignee : assignee_list.map(a => a.name);

        const filteredTasks = Object.keys(data).reduce((acc, categoryKey) => {
            const categoryTasks = data[categoryKey];

            const filteredCategoryTasks = categoryTasks.filter(task => {
                const taskDate = new Date(task.scheduledDate.split('-').reverse().join('-'));
                return (
                    taskDate >= from && taskDate <= to &&
                    assigneeFilter.includes(task.assignedTo) &&
                    (selectedFacility === 'all' || task.facility === selectedFacility)
                );
            });

            acc[categoryKey] = filteredCategoryTasks;
            return acc;
        }, {});

        setFilteredTasks(filteredTasks);
    };

    const handleChangeToDate = (e) => {
        setToDate(e.target.value);
        const from = new Date(fromDate);
        const to = new Date(e.target.value);

        const assigneeFilter = selectedAssignee.length > 0 ? selectedAssignee : assignee_list.map(a => a.name);

        const filteredTasks = Object.keys(data).reduce((acc, categoryKey) => {
            const categoryTasks = data[categoryKey];

            const filteredCategoryTasks = categoryTasks.filter(task => {
                const taskDate = new Date(task.scheduledDate.split('-').reverse().join('-'));
                return (
                    taskDate >= from && taskDate <= to &&
                    assigneeFilter.includes(task.assignedTo) &&
                    (selectedFacility === 'all' || task.facility === selectedFacility)
                );
            });

            acc[categoryKey] = filteredCategoryTasks;
            return acc;
        }, {});

        setFilteredTasks(filteredTasks);
    };

    const handleFacilityChange = (e) => {
        const facility = e.target.value;
        setSelectedFacility(facility);
        const assigneeFilter = selectedAssignee.length > 0 ? selectedAssignee : assignee_list.map(a => a.name);

        const filteredTasks = Object.keys(data).reduce((acc, categoryKey) => {
            const categoryTasks = data[categoryKey];

            const filteredCategoryTasks = categoryTasks.filter(task => {
                const taskDate = new Date(task.scheduledDate.split('-').reverse().join('-'));
                const from = new Date(fromDate);
                const to = new Date(toDate);

                return (
                    task.eventName.toLowerCase().includes(searchText.toLowerCase()) &&
                    assigneeFilter.includes(task.assignedTo) &&
                    taskDate >= from && taskDate <= to &&
                    (facility === 'all' || task.facility === facility)
                );
            });

            acc[categoryKey] = filteredCategoryTasks;
            return acc;
        }, {});

        setTasks(filteredTasks);
        setFilteredTasks(filteredTasks);
    };

    const handelSectionChange = (tab) => {
        setSections(sections.map((section) => {
            if (section.tab === tab) {
                return { ...section, isOpen: !(section.isOpen) }
            } else {
                return section
            }
        }))
    }

    return (
        <>
            <NavBarTop />
            <LeftNavBar />
            <div className='tasks-screen-container'>
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-2'>
                    <div className='d-flex align-items-center gap-3'>
                        <h3 className="invoice-heading" >Work Log</h3>
                    </div>
                    <div className='scheduler-dropdown'>
                        <IoAddOutline style={{ height: "20px", width: "20px" }} />
                        <select className='worklog-dropdown'>
                            <option value="" hidden>Schedule Event</option>
                            <option value="monthly">Monthly</option>
                            <option value="quarterly">Quarterly</option>
                            <option value="yearly">Yearly</option>
                        </select>
                    </div>
                </div>
                <div className='d-flex mb-2 justify-content-between align-items-end gap-2 flex-wrap'>
                    <Search value={searchText} handleSearch={handleSearchTasks} />

                    {facilitiesList && <Dropdown
                        selectedOption={selectedFacility}
                        handleChange={handleFacilityChange}
                        options={facilitiesList}
                        name='Facility'
                        className='dropdown-list'
                    />}

                    <div className='d-flex gap-2'>
                        <div className='d-flex flex-column'>
                            <label>From date</label>
                            <input type='date' max={toDate} value={fromDate} onChange={handleChangeFromDate} className='fin_man_date_range' />
                        </div>
                        <div className='d-flex flex-column'>
                            <label>To date</label>
                            <input type='date' min={fromDate} value={toDate} onChange={handleChangeToDate} className='fin_man_date_range' />
                        </div>
                    </div>
                    {assignee_list && <div className='d-flex'>
                        {assignee.map((assignee, index) => {
                            return (
                                <div key={index} className={`worklog-assignee ${selectedAssignee?.some(selected => selected === assignee?.name) ? 'selected-worklog-assignee' : ''}`}
                                    style={{ marginLeft: index !== 0 ? '-8px' : '0', backgroundColor: randomColor(assignee?.name) }}
                                    onClick={() => filterAssignee(assignee)}
                                    title={assignee?.name}
                                >
                                    <span>{getInitials(assignee?.name)}</span>
                                </div>
                            )
                        })}
                        {remainingAssignee.length > 0 && <div className='d-flex flex-column'>
                            <div className={`worklog-assignee ${selectedRemainingAssignee ? 'selected-worklog-assignee' : ''}`} style={{ marginLeft: '-8px', backgroundColor: "#E1E1E1", color: "black" }}
                                onClick={() => setSelectedRemainingAssignee(!selectedRemainingAssignee)}
                            >
                                <span>+{remainingAssignee.length}</span>
                            </div>
                            <div className='remaining-assignee' onMouseLeave={() => setSelectedRemainingAssignee(false)}>
                                {selectedRemainingAssignee && <div className='remaining-assignee-list'  >
                                    {selectedRemainingAssignee && remainingAssignee.map((assignee, index) => {
                                        return (
                                            <div className='d-flex align-items-center gap-2 C-pointer'
                                                onClick={() => filterAssignee(assignee)}
                                            >
                                                <div>
                                                    <input type='checkbox' checked={selectedAssignee?.some(selected => selected === assignee?.name)} />
                                                </div>
                                                <div key={index} className='worklog-assignee' style={{ width: "30px", height: "30px", backgroundColor: randomColor(assignee.name) }}
                                                >
                                                    <span>{getInitials(assignee?.name)}</span>
                                                </div>
                                                <div style={{ fontSize: "12px" }}>
                                                    <span>{assignee.name}</span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>}
                            </div>
                        </div>}
                    </div>}
                </div>
                <WorkLog columns={columnNames} assignee_list={assignee_list} data={filteredTasks} facilitiesList={facilitiesList} sections={sections} handelSectionChange={handelSectionChange} />
            </div>

        </>
    )
}
