import React,{useEffect} from 'react'

export default function Checkbox({ object,rowIndex,colIndex,formUse,handleFormData,data }) {

    useEffect(() => {
        formUse && handleFormData && handleFormData(object.content+"-"+rowIndex+colIndex,false)
    },[])

    return (
        <div className='d-flex gap-3 align-items-center' style={{padding:"8px"}}>
            <input type='checkbox' id={object.content}
            checked={data && data[object.content+"-"+rowIndex+colIndex]}
            disabled={data && data}
            onChange={(e) => {formUse && handleFormData && handleFormData(object.content+"-"+rowIndex+colIndex,e.target.checked)}} />
            <label htmlFor={object.content}>{object.content}</label>
        </div>
    )
}
