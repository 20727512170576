import React, { useEffect } from 'react'

export default function Date({ object, rowIndex, colIndex, data, handleFormData, formUse }) {

  useEffect(() => {
    formUse && handleFormData && handleFormData(object.content + "-" + rowIndex + colIndex, data ? data[object.content + "-" + rowIndex + colIndex] : "")
  }, [])

  return (
    <div className='d-flex justify-content-between align-items-center gap-3 w-100' style={{ padding: "8px" }}>
      <label className='w-50'>{object.content}</label>
      {data && data ? <span style={{ width: "85%", fontSize: "12px", color: "gray" }} >{data[object.content + "-" + rowIndex + colIndex]}</span> : <input type='date' className='form-control' style={{ width: "80%" }}
        value={data && data[object.content + "-" + rowIndex + colIndex]}
        disabled={data && data}
        onChange={(e) => handleFormData && handleFormData(object.content + "-" + rowIndex + colIndex, e.target.value)} />}
    </div>
  )
}
