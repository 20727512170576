import React, { useEffect, useState } from 'react'
import { GoSearch } from "react-icons/go";
import { MdCurrencyRupee } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Table } from 'react-bootstrap'
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import { useDispatch, useSelector } from 'react-redux';
import { addNewLicensePermit, commercialHeadUploadFacilityDocument, commercialHeadUploadLicenseDocument, finmanGetMeterReadings, getGstSubmissionLogs, handleDocumentLoaderFalse, handleDocumentLoaderTrue, LicensesAndPermitsAPI, PUT_CH_UPDATE_METER_SUCCESS } from '../../Redux/Actions/Actions';
import { set } from 'date-fns';
import Loader from '../Reusable_Components/loader';
import AlertPopUp from '../Reusable_Components/Alert';
import { validateValue } from "../Reusable_Components/ReusableFunctions";
import { SiVelog } from 'react-icons/si';
import Footer from "../Login/Footer";
import UploadComponent from '../Reusable_Components/UploadComponent';
import { IoDocumentTextOutline } from "react-icons/io5";
import axios from 'axios';
import { base_url } from '../../utilities';

export default function LicensesAndPermits() {

    const contractApiLoader = useSelector(state => state.contractApiLoader)
    const licensesAndPermitsData = useSelector(state => state.licensesAndPermitsData)


    function formatDate(date) {
        const d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
    }

    const [currentPage, setCurrentPage] = useState(1)
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState({})
    const [selectMeter, setSelectMeter] = useState("all")
    const [sortOrder, setSortOrder] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const dispatch = useDispatch();
    const error = useSelector(state => state.error);
    const [isLoading, setIsLoading] = useState(false)
    //for alert pop up
    const [alertShow, setAlertShow] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertValue, setAlertValue] = useState('')
    const [alertButtons, setAlertButtons] = useState([])
    const [alertAction, setAlertAction] = useState([])
    const [show, setShow] = useState(false);
    const [viewClicked, setViewClicked] = useState(false)
    const [licenseType, setLicenseType] = useState('')
    const [licenseName, setLicenseName] = useState('')
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [expiryReminder, setExpiryReminder] = useState('')
    const [renewal, setRenewal] = useState('')
    const [description, setDescription] = useState('')
    const [licenseTypeError, setLicenseTypeError] = useState(false)
    const [licenseNameError, setLicenseNameError] = useState(false)
    const [expiryReminderError, setExpiryReminderError] = useState(false)
    const [renewalError, setRenewalError] = useState(false)
    const [descriptionError, setDescriptionError] = useState(false)
    // file upload states
    const [documentsListArray, setDocumentListArray] = useState([])
    const [uploadedFiles, setUploadedFiles] = useState();
    const [showConfirmDeleteModel, setConfirmDeleteModel] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState();
    const [errorValue, setErrorValue] = useState(null)
    const [documentList, setDocumentList] = useState([]);
    const commercialHeadUploadFacilityDocumentList = useSelector(state => state.commercialHeadUploadFacilityDocumentList);
    const commercialHeadGetFacilityDocumentsList = useSelector(state => state.commercialHeadGetFacilityDocumentsList);



    const fetchReports = () => {
        const data = {
            params : {
                search_text : searchTerm,
                filter_condition : JSON.stringify({
                    facility_id: localStorage.getItem('selected_facility_id')
                }),
            }
        };
        dispatch(LicensesAndPermitsAPI(data))
    }

    useEffect(() => {
        // setErrorValue(error?.response?.data.message);
        if (error?.response?.data.message || error?.data?.data[1]?.message) {
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error?.data?.data[1] ? error?.data?.data[1].message : error?.response?.data.message)
            setAlertButtons([{
                name: 'Close', action: () => {
                    setAlertShow(false)
                    dispatch({
                        type: PUT_CH_UPDATE_METER_SUCCESS,
                        payload: null
                    })
                }
            }])
        }
        setIsLoading(false);
    }, [error])

    useEffect(() => {
        fetchReports()
    }, [])

    useEffect(() => {
        if (licensesAndPermitsData?.length > 0) {
            setFilteredData([...licensesAndPermitsData])
        } else {
            setFilteredData([])
        }
    }, [licensesAndPermitsData])

    useEffect(() => {
        if (commercialHeadGetFacilityDocumentsList) {
            setDocumentListArray(commercialHeadGetFacilityDocumentsList)
            setIsLoading(false)
        }
    }, [commercialHeadGetFacilityDocumentsList])

    const backFunction = () => {
        setShow(false)
        setViewClicked(false)
        setLicenseType('')
        setLicenseName('')
        setStartDate('')
        setEndDate('')
        setExpiryReminder('')
        setRenewal('')
        setDescription('')
    }

    const saveLicenesAndPermits = () => {
        const data = {
            "facility_id": localStorage.getItem('selected_facility_id'),
            "license_type": licenseType,
            "license_name": licenseName,
            "s3_key":   uploadedFiles[0].file_name,
            "start_date": startDate,
            "end_date": endDate,
            "expiry_reminder": expiryReminder,
            "renewal": renewal,
            "description": description,
            "username": localStorage.getItem('username')
        }
    dispatch(addNewLicensePermit(data))
    }

    const handleFilesSelected = (files) => {
        const processedFiles = [];
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = function () {
                const base64FileContent = reader.result.split(",")[1];
                const required_id = localStorage.getItem('selected_facility_id');
                const processedFile = {
                    file_content: base64FileContent,
                    object_type: "licenses_permits",
                    file_description: file.description,
                    file_type: file.file.type.split("/")[1],
                    file_name: file.file.name,
                    facility_id: required_id,
                    license_type: licenseType,
                    file_size: file.file.size,
                    flag: file.flag,
                    user_id: localStorage.getItem('username'),
                };
                processedFiles.push(processedFile);
                if (processedFiles.length === files.length) {
                    setUploadedFiles(processedFiles);
                }
            };
            reader.readAsDataURL(file.file);
        });
    };
    const confirmDeleteDocument = (index) => {
        setConfirmDeleteModel(true);
        setSelectedIndex(index);
    }

    const handleDocumentUpload = (index) => {
        dispatch(handleDocumentLoaderTrue())
        dispatch(commercialHeadUploadLicenseDocument(
            {
                files: [uploadedFiles[index]]
            }
        ))
    }

    const handleErrorFromDocument = (value) => {
        setErrorValue(value)
    }
    useEffect(() => {
        let documentListTemp = [...documentList];
        commercialHeadUploadFacilityDocumentList?.forEach(item => {
            if (!documentListTemp.includes(item)) {
                documentListTemp.push(item);
            }
        });
        setDocumentList([...documentListTemp]);
    }, [commercialHeadUploadFacilityDocumentList])

    const handleDocumentDelete = () => {
        dispatch(handleDocumentLoaderTrue())
        if (documentList.length > selectedIndex) {
            axios.delete(base_url + `documents`,
                {
                    data: {
                        s3_keys: [documentList[selectedIndex]?.key],

                    }
                }
            )
                .then((response) => {
                    if (response?.data?.data[0] === 'error') {
                        setErrorValue(response?.data?.data[1].message)
                        setAlertShow(true)
                        setAlertType('error')
                        setAlertMessage('Error')
                        setAlertValue(response?.data?.data[1].message)
                        setAlertButtons([
                            {
                                name: 'Close', action: () => {
                                    setAlertShow(false)
                                }
                            },
                        ])
                        setIsLoading(false)
                    }
                    else {
                        setConfirmDeleteModel(false)
                        dispatch(handleDocumentLoaderFalse())
                        let newDocumentList = [...documentList];
                        newDocumentList.splice(selectedIndex, 1);
                        setDocumentList(newDocumentList);
                    }
                })
                .catch((error) => {
                    setErrorValue(error?.response?.data.message);
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error')
                    setAlertValue(error?.response?.data.message)
                    setAlertButtons([{
                        name: 'Close', action: () => {
                            setAlertShow(false)
                        }
                    }])
                    setIsLoading(false)
                })

        }
        else {
            setConfirmDeleteModel(false)
        }

    }

    

    return (
        <>
            <>
                <NavBarTop />
                <LeftNavBar />
            </>
            {isLoading && <Loader />}
            {contractApiLoader && <Loader />}
            <AlertPopUp alertShow={alertShow} alertType={alertType} alertMsg={alertMessage} alertValue={alertValue} alertButtons={alertButtons} />
            {(currentPage === 1) && <div className='meter_container_reports '>
                <div className='fm-invoice-con'>
                    <div className='fm-invoice-heading-con  mb-1'>
                        <h3 className="invoice-heading">Licenses and Permits</h3>
                    </div>
                    <button className="invoice_btn" style={{ color: 'white' }}
                            onClick={() => {
                                // setCurrentPage(3)
                                // generateInvoiceButtonClicked()
                                setShow(true)
                            }}>Add New License +</button>
                </div>
                <div className='fm-invoice-con'>
                    <div className='fin-meter-reports-con'>
                    <div className='d-flex align-items-center gap-3 mb-3'>
                        
                            <div className='fin_man_meter_search_bar_container'>
                                <input style={{ marginTop: '30px', height: '2.5rem' }} 
                                    type='search' placeholder='Search' 
                                    value={searchTerm}
                                    onChange={(e) => {
                                        const input = e.target.value
                                        setSearchTerm(input)
                                    }}
                                    className='fin_man_meter_search_bar' />
                                <GoSearch
                                    onClick={(e) => {
                                        
                                    }} 
                                    style={{ marginTop: '25px' }} className='fin_man_meter_search_bar_icon C-pointer' />
                            </div>

                        </div>

                        <button className="invoice_btn" style={{ marginTop: '18px', color: 'white', height: '20px !important' }} onClick={() => {
                            fetchReports()
                        }}>Apply</button>
                        <button className="invoice_btn" style={{ marginTop: '18px', color: 'white', height: '20px !important' }} onClick={() => {
                            setSearchTerm('')
                            const data = {
                                params : {
                                    search_text : '',
                                    filter_condition : JSON.stringify({
                                        facility_id: localStorage.getItem('selected_facility_id')
                                    }),
                                }
                            };
                            dispatch(LicensesAndPermitsAPI(data))
                        }}>Clear</button>
                    </div>
                </div>
                {selectMeter && <div className='fin_meter_reports_table' style={{ height: '53vh' }}>
                <table>
                    <colgroup>
                        <col style={{ width: 'auto' }} /> 
                        <col style={{ width: 'auto' }} /> 
                        <col style={{ width: 'auto' }} />
                        <col style={{ width: 'auto' }} /> 
                        <col style={{ width: 'auto' }} /> 
                        <col style={{ width: 'auto' }} />
                        <col style={{ width: 'auto' }} /> 
                    </colgroup>
                    <thead>
                        <tr className='fin_man_table_head commercial-head-table text-center'>
                            <th>License Name</th>
                            <th>License Type</th>
                            <th>End Date</th>
                            <th>Documents</th>
                            <th>Description</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody className='fin_man_table_body'>
                        {filteredData && filteredData?.length !== 0 ? (
                            filteredData?.map((each, index) => {
                                return (
                                    <tr key={index} className='table_row'>
                                        <td>{each?.license_name}</td>
                                        <td>{each?.license_type}</td>
                                        <td className='text-end'>{each?.end_date}</td>
                                        <td className='text-center C-pointer'><IoDocumentTextOutline style={{width:'20px',height:'20px'}} /></td>
                                        <td style={{ wordWrap: 'break-word', whiteSpace: 'normal', maxWidth: '320px' }}>{each?.description}</td>
                                        <td className='text-center'>
                                        <img src="./images/icons/ViewEYE.svg" title="view" onClick={() => {
                                            // handleViewButton(each.invoice_number, each.status, each.invoice_type)
                                            setViewClicked(true)
                                            setLicenseType(each.license_type)
                                            setLicenseName(each.license_name)
                                            setStartDate(new Date(each.start_date))
                                            setEndDate(new Date(each.end_date))
                                            setExpiryReminder(each.expiry_reminder)
                                            setRenewal(each.renewal)
                                            setDescription(each.description)
                                            setShow(true)

                                        }} alt="view imag" />
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan='10' className='text-center'>No Records Found</td>
                            </tr>
                        )}
                    </tbody>
                </table>


                </div>}
            </div>}
            <Modal keyboard={false} backdrop="static" 
                show={show} 
                centered 
                onHide={() => setShow(false)} 
                size='lg'>
                    <Modal.Header >
                        <Modal.Title>{viewClicked?'License Details':'Add New License'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex flex-column gap-3'>
                        <div className='d-flex justify-content-between gap-3'>
                        <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                        <label className='add-facility-modal-label'>License Name{!viewClicked && <span style={{ color: 'red' }}>*</span>}:</label>
                                        <div className='d-flex flex-column'>
                                            <input type='text'
                                                disabled={viewClicked}
                                                className={`form-control add-facility-input-fields `}
                                                value={licenseName}
                                                onChange={(e)=>{
                                                    setLicenseName(e.target.value)
                                                }}
                                                required
                                            />

                                            {/* {facilityNameError && <div className="invalid-input">Only a-z,A-Z,0-9,spaces,-,_,/ are allowed.</div>}
                                            {uniqueFacilityNameError && <div className="invalid-input">Facility name should be unique!</div>}
                                            {lenghtError && <div className="invalid-input">Must be between 3 to 200 characters</div>} */}
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                        <label className='add-facility-modal-label'>License Type{!viewClicked && <span style={{ color: 'red' }}>*</span>}:</label>
                                        <div className='d-flex flex-column'>
                                            <input type='text'
                                                disabled={viewClicked}
                                                className={`form-control add-facility-input-fields `}
                                                value={licenseType}
                                                onChange={(e)=>{
                                                    setLicenseType(e.target.value)
                                                    setLicenseTypeError(false)
                                                }}
                                                required
                                            />
                                        </div>
                                    </div>
                            </div>
                            <div className='d-flex justify-content-between gap-3'>
                                    <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                        <label className='add-facility-modal-label'>Start Date{!viewClicked && <span style={{ color: 'red' }}>*</span>}:</label>
                                        <div className='d-flex flex-column'>
                                            <input type='date'
                                            disabled={viewClicked}
                                                className={`form-control add-facility-input-fields `}
                                                value={startDate ? startDate : ''}
                                                onChange={(e)=>{
                                                    setStartDate(e.target.value)
                                                    setLicenseTypeError(false)
                                                }}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                        <label className='add-facility-modal-label'>End Date{!viewClicked && <span style={{ color: 'red' }}>*</span>}:</label>
                                        <div className='d-flex flex-column'>
                                            <input type='date'
                                            disabled={viewClicked}
                                                className={`form-control add-facility-input-fields `}
                                                value={endDate ? endDate : ''}
                                                onChange={(e)=>{
                                                    setEndDate(e.target.value)
                                                }}
                                                required
                                            />

                                            {/* {facilityNameError && <div className="invalid-input">Only a-z,A-Z,0-9,spaces,-,_,/ are allowed.</div>}
                                            {uniqueFacilityNameError && <div className="invalid-input">Facility name should be unique!</div>}
                                            {lenghtError && <div className="invalid-input">Must be between 3 to 200 characters</div>} */}
                                        </div>
                                    </div>
                            </div>
                            <div className='d-flex justify-content-between gap-3'>
                                    <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                        <label className='add-facility-modal-label'>Expiry Reminder{!viewClicked && <span style={{ color: 'red' }}>*</span>}:</label>
                                        <div className='d-flex flex-column'>
                                        <select className='commercial-head-dropdown' 
                                        disabled={viewClicked}
                                        value={expiryReminder}
                                        onChange={(e)=>{
                                            setExpiryReminder(e.target.value)
                                        }}
                                        required>
                                                <option hidden>Select</option>
                                                <option value='1 Month'>1 Month</option>
                                                <option value='2 Months'>2 Months</option>
                                                <option value='3 Months'>3 Months</option>
                                                <option value='6 Months'>6 Months</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                        <label className='add-facility-modal-label'>Renewal{!viewClicked && <span style={{ color: 'red' }}>*</span>}:</label>
                                        <div className='d-flex flex-column'>
                                        <select className='commercial-head-dropdown' 
                                        disabled={viewClicked}
                                        value={renewal}
                                        onChange={(e)=>{
                                            setRenewal(e.target.value)
                                        }}
                                        required>
                                                <option hidden>Select</option>
                                                <option value='Quarterly'>Quarterly</option>
                                                <option value='Half Yearly'>Half Yearly</option>
                                                <option value='Yearly'>Yearly</option>
                                            </select>
                                        </div>
                                    </div>
                            </div>
                            <div className='d-flex flex-column justify-content-between gap-2 align-items-start'>
                                <label className='add-facility-modal-label'>Documents{!viewClicked && <span style={{ color: 'red' }}>*</span>}:</label>
                                <UploadComponent
                                    onFilesSelected={handleFilesSelected}
                                    uploadMethod="single"
                                    viewMode={viewClicked}
                                    uploadedFileData={documentsListArray}
                                    uploadActionClicked={handleDocumentUpload}
                                    deleteSpecificFileMain={confirmDeleteDocument}
                                    maxNumberOfFiles={1}
                                    handleErrorFromDocument={handleErrorFromDocument}
                                    idType={"other"}
                                    acceptedFileTypes={".pdf, .jpeg, .jpg, .png"}
                                />
                            </div>
                            <div className='d-flex flex-column justify-content-between gap-2 align-items-start'>
                                <label className='add-facility-modal-label'>Document Description{!viewClicked && <span style={{ color: 'red' }}>*</span>}:</label>
                                <textarea disabled={viewClicked} className='form-control' required style={{ border: "0.5px solid #9D9D9C",width:'100%' }} value={description} 
                                onChange={(e)=>{
                                    setDescription(e.target.value)
                                }}
                                 />
                            </div>
                            
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn btn-light px-5' style={{ border: "1px solid #878787", backgroundColor: "#FFFFFF" }} 
                        onClick={() => {
                            backFunction()
                        }}
                        >
                            Back
                        </Button>
                        {!viewClicked && <Button className='btn btn-secondary px-5' 
                        onClick={()=>{
                            saveLicenesAndPermits()
                        }} 
                        // disabled={saveDisabled || selectedState === '' || uniqueFacilityNameError ||
                        //     selectedCity === '' || facilityName === '' || facilityArea === "" || electricityRate === '' || waterRate === '' || dgRate === '' || address === '' || lenghtError}
                        >
                            Save
                        </Button>}
                    </Modal.Footer>
                </Modal>
            <Footer />
            {/* </> */}
        </>
    )
}
